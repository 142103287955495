<script setup>
import { ref, computed, onBeforeMount } from 'vue'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'
import { cronjobService, flowService } from 'src/services'
import cronParser from 'cron-parser'
import cronstrue from 'cronstrue'
import 'cronstrue/locales/de'

import SqDialog from 'components/Common/SqDialog.vue'

const emit = defineEmits(['saved'])
const props = defineProps({
  page: Number,
  itemsPerPage: Number,
  cronjob: Object
})

const $store = useStore()
const { t, locale } = useI18n()

const isLoading = ref(false)
const selectedFlow = ref(null)
const cronjobExpression = ref('')
const cronjobActive = ref(true)
const flows = ref([])
const optionFilter = ref('')
const isCronValid = ref(true)

// Computed properties
const cronJobModalLabel = computed(() =>
  t(`cronjob.${props.cronjob ? 'updateCronjob' : 'addCronjob'}`)
)

const options = computed(() => {
  if (!flows.value?.length) return []
  return flows.value
    .map(flow => ({ value: flow.id, label: flow.name }))
    .filter(opt => opt.label.toLowerCase().includes(optionFilter.value.toLowerCase()))
})

const cronReadable = computed(() => {
  try {
    cronParser.parseExpression(cronjobExpression.value)
    isCronValid.value = true
    return locale.value === 'de-DE'
      ? cronstrue.toString(cronjobExpression.value, { locale: 'de' })
      : cronstrue.toString(cronjobExpression.value)
  } catch {
    isCronValid.value = false
    return t('cronjob.invalidExpression')
  }
})

// Methods
function initialize() {
  if (props.cronjob) {
    selectedFlow.value = {
      value: props.cronjob.flow.id,
      label: `Flow ${props.cronjob.flow.id}: ${props.cronjob.flow.name}`
    }
    cronjobExpression.value = props.cronjob.cronExpression
    cronjobActive.value = props.cronjob?.active
  }
}

function resetState() {
  selectedFlow.value = null
  cronjobExpression.value = ''
  cronjobActive.value = false
}

function saveCronjob() {
  const formData = {
    flow: selectedFlow.value.value,
    cronExpression: cronjobExpression.value,
    active: cronjobActive.value
  }

  isLoading.value = true

  const serviceCall = props.cronjob
    ? cronjobService.updateCronjob(props.cronjob, formData, handleResponse)
    : cronjobService.createCronjob(formData, handleResponse)
}

function handleResponse(data) {
  const type = props.cronjob ? 'update' : 'create'
  isLoading.value = false

  if (data?.data['@type'] === 'hydra:Error') {
    $store.dispatch('alert/error', `cronjob.${type}CronjobFail`, { root: true })
  } else {
    $store.dispatch('alert/success', `cronjob.${type}CronjobSuccess`, { root: true })
    emit('saved', data)
    resetState()
  }
}

function rules(val) {
  if (!val || val.length <= 0) {
    return t('cronjob.form.rules.empty')
  }
  return true
}

function filterFn(val, update) {
  update(() => {
    optionFilter.value = val
  })
}

async function handleShow() {
  initialize()
  isLoading.value = true
  const { data } = await flowService.getFlows(1, 50)
  isLoading.value = false

  if (data?.status === 401) {
    $store.dispatch('authentication/expired', data, { root: true })
  } else {
    flows.value = data['hydra:member']
  }
}
</script>

<template>
  <sq-dialog
    :type="props.cronjob ? 'update' : 'create'"
    :loading="isLoading"
    @save="saveCronjob"
    @show="handleShow"
    @hide="resetState"
  >
    <template #title>{{ cronJobModalLabel }}</template>

    <template #content>
      <q-form @submit.prevent="saveCronjob">
        <q-select
          v-model="selectedFlow"
          dense outlined use-input hide-selected fill-input
          :disable="isLoading"
          :options="options"
          :label="$t('cronjob.form.flowId')"
          input-debounce="0"
          class="app-cronjob-flowid-input q-mt-md"
          @filter="filterFn"
        >
          <template #no-option>
            <q-item>
              <q-item-section class="text-grey">{{ $t('cronjob.noFlows') }}</q-item-section>
            </q-item>
          </template>
        </q-select>

        <q-input
          v-model="cronjobExpression"
          required dense outlined
          placeholder="* * * * *"
          :label="$t('cronjob.form.expression')"
          :rules="[rules]"
          class="app-cronjob-expression-input q-mt-sm"
        />

        <div
          v-if="cronjobExpression !== ''"
          class="app-cronjob-expression-readable text-h6 text-center"
        >
          {{ cronReadable }}
        </div>

        <q-toggle
          v-model="cronjobActive"
          :label="$t('cronjob.form.activeState')"
          class="app-cronjob-active-toggle q-mt-sm"
        />
      </q-form>
    </template>

    <template #saveButton>
      <q-btn
        flat type="submit" color="primary"
        :disabled="!selectedFlow || !cronjobExpression || !isCronValid"
        :label="$t('general.save')"
        :class="{ disabled: !cronjobExpression }"
        @click="saveCronjob"
      />
    </template>
  </sq-dialog>
</template>

<style lang="scss">
.app-cronjob-name-input, .app-cronjob-mail-input {
  .q-field__native.q-placeholder {
    padding-left: 1rem;
  }
}
</style>
