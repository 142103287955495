<script>
import {defineComponent, ref} from 'vue'
import JsonEditor from 'components/JsonEditor.vue'
import LoadingIndicator from 'components/LoadingIndicator'
import {useQuasar} from 'quasar'
import DeleteJdmEnvironmentModal from "./DeleteJdmEnvironmentModal";

import SqDialog from 'components/Common/SqDialog.vue'
import {environmentService} from 'src/services/environmentService'
import {versionService} from 'src/services/versionService'

export default defineComponent({
  name: 'JdmEnvironmentModal',

  components: {
    SqDialog,
    LoadingIndicator,
    JsonEditor
  },
  emits: ['save'],

  props: {
    jobDispatcherMapping: {
      type: Object,
      required: true
    },
    environments: {
      type: Array,
      required: true
    }
  },

  setup (props) {
    const $q = useQuasar();

    const isUpdatingJdmEnvironment = ref(false)
    const environmentFilter = ref('')
    const jdmId = ref(props.jobDispatcherMapping.id)

    return {
      qInstance: $q,
      isUpdatingJdmEnvironment,
      environmentFilter,
      jdmId
    }
  },
  data () {
    return {
      isLoading: true,
      validJson: null,
      showValidationErrorBanner: false,
      errorMessage: this.$t('browser.modal.invalidInfo'),
      hasJdmEnvConfig: false,
      localEnvironmentConfigs: ref([JSON.stringify([], null, 2)]),
      originalLocalEnvironmentConfigs: this.localEnvironmentConfigs,
      jdmEnvConfigs: ref([])
    }
  },

  computed: {
    environmentOptions: function () {
      return this.environments
    },
    isInvalid() {
      return (this.isLoading || this.isUpdatingJdmEnvironment || !this.validJson || this.envAssignedMultipleTimes.length > 0);
    },
    maxAvailableEnvReached() {
      return this.jdmEnvConfigs.length >= this.environmentOptions.length;
    },
    envAssignedMultipleTimes() {
      const envIds = this.jdmEnvConfigs.map(env => env.environment.id);
      const envNames = this.jdmEnvConfigs.map(env => env.environment.name);
      // Determine which environments are assigned multiple times
      return Array.from(new Set(envNames.filter((item, index) => envNames.indexOf(item) !== index)));
    }
  },

  methods: {

    checkValidJson(valid) {
      this.validJson = valid;
    },

    filterFn (val, update) {
      update(() => {
        this.environmentFilter = val;
      })
    },

    openDeleteJdmEnvModal(index) {

      this.qInstance.dialog({
        component: DeleteJdmEnvironmentModal,
        // props forwarded to your custom component
        componentProps: {
          jdm: this.jdmId,
          environment: this.jdmEnvConfigs[index].environment.name,
        }
      }).onOk(() => {
        this.deleteJdmEnvConfig(index);
      })
    },

    validate() {
      // TODO: Check for errorBag
      this.saveEnvironment()
    },

    async saveEnvironment() {
      this.isLoading = true;
      try {
        // Before saving, we need to update the configs in the jdmEnvConfigs array
        // Only update the configs that have changed
        // First get the indexes of all changed configs - we compare localEnvironmentConfigs with originalLocalEnvironmentConfigs to find them
        const changedConfigs = this.localEnvironmentConfigs.map((config, index) => {
          if (config !== this.originalLocalEnvironmentConfigs[index]) {
            return index;
          }
        }).filter(index => index !== undefined);
        // Parse the changed configs to JSON and update the jdmEnvConfigs array
        changedConfigs.forEach(index => {
          this.jdmEnvConfigs[index].config = JSON.parse(this.localEnvironmentConfigs[index]);
        });

        const transformedJdmEnvConfigs = this.jdmEnvConfigs.map(item => ({
          id: item.id,
          jobDispatcherMapping: item.jobDispatcherMapping["@id"],
          environment: item.environment["@id"],
          config: item.config
        }));

        await environmentService.replaceMultipleJdmEnvironments(transformedJdmEnvConfigs, (data) => {
          if(data?.['@type'] === "hydra:Error") {
            this.$store.dispatch('alert/error', 'flow.overview.detail.modal.jdmEnvConfigEditFail', { root: true });
          } else {
            this.$store.dispatch('alert/success', 'flow.overview.detail.modal.jdmEnvConfigEditSuccess');
          }
          this.$emit('save', data)
          this.getJdmEnvironments();
        })

        this.isLoading = false;
      } catch(e) {
        this.$store.dispatch('alert/error', e);
      }
    },

    reset() {
      this.getJdmEnvironments(); // Refresh data, otherwise canceling will keep the old data in view when reopening
    },

    async getJdmEnvironments() {
      this.isLoading = true;

      let jdmEnvData = null
      const jdmPromise = new Promise((resolve) => {
        environmentService.getJdmEnvironments(null, (data) => {
          jdmEnvData = data["hydra:member"];
          this.hasJdmEnvConfig = jdmEnvData.some(
            item => item.jobDispatcherMapping["@id"] === `/api/job_dispatcher_mappings/${this.jdmId}`
          );
          resolve(); // Ensure completion
        });
      });

      await jdmPromise; // Wait for JdmEnvironments before continuing

      const filteredData = jdmEnvData.filter(item =>
        item.jobDispatcherMapping["@id"] === `/api/job_dispatcher_mappings/${this.jdmId}`
      );
      this.jdmEnvConfigs = filteredData;
      this.localEnvironmentConfigs = filteredData.map(item => JSON.stringify(item.config));
      // get the val of localEnvironmentConfigs but not the reference
      this.originalLocalEnvironmentConfigs = filteredData.map(item => JSON.stringify(item.config))

      this.isLoading = false;
    },

    addJdmEnvConfig() {
      // Get first option from environmentOptions that is not already assigned in jdmEnvConfigs
      // There'll always be one available, since the button to trigger this is disabled otherwise
      const firstAvailableEnv = this.environmentOptions.find(env => !this.jdmEnvConfigs.some(jdmEnv => jdmEnv.environment.id === env.id));

      environmentService.createJdmEnvironment(this.jdmId, firstAvailableEnv.id, {},(data) => {
        if(data?.data['@type'] === "hydra:Error") {
          this.$store.dispatch('alert/error', 'flow.overview.detail.modal.jdmEnvConfigCreateFail', { root: true });
        } else {
          this.$store.dispatch('alert/success', 'flow.overview.detail.modal.jdmEnvConfigCreateSuccess');
          this.getJdmEnvironments();
        }
      })
    },

    async deleteJdmEnvConfig(index) {
      await environmentService.deleteJdmEnvironment(this.jdmEnvConfigs[index].id, (data) => {
        if(data.data?.['@type'] === "hydra:Error") {
          this.$store.dispatch('alert/error', 'flow.overview.detail.modal.jdmEnvConfigDeleteFail', { root: true });
        } else {
          this.$store.dispatch('alert/success', 'flow.overview.detail.modal.jdmEnvConfigDeleteSuccess');
          this.getJdmEnvironments();
        }
      })
    }
  },

  mounted() {
    this.getJdmEnvironments();
  }
})
</script>

<template>
  <sq-dialog
    :type="hasJdmEnvConfig ? 'edit' : 'create'"
    size="md"
    :loading="isLoading || isUpdatingJdmEnvironment"
    @hide="reset()"
  >
    <template #title>
      {{ hasJdmEnvConfig ? $t('flow.overview.detail.modal.editJdmEnvironment') : $t('flow.overview.detail.modal.createJdmEnvironment') }}
    </template>

    <template #content>
      <q-card-section class="context-content">
        <q-banner
          v-if="showValidationErrorBanner"
          inline-actions
          class="text-white bg-red q-animate--fade"
        >
          {{ errorMessage }}
          <template #action>
            <q-btn
              flat
              color="white"
              :label="$t('general.close')"
              @click="showValidationErrorBanner = false"
            />
          </template>
        </q-banner>

        <h2><q-icon name="warning" color="warning" class="q-mr-sm" />{{ $t('flow.overview.detail.modal.uniqueEnvWarning') }}</h2>

        <hr>

        <!-- Need to set v-if, otherwise there's a display bug when deleting the last jdmEnvConfig -->
        <q-item-section v-if="jdmEnvConfigs.length > 0" class="sq-jdm-env-config-container" v-for="(jdmEnvConfig, jdmEnvConfigIndex) in jdmEnvConfigs">

          <div class="flex justify-between q-mt-sm">
            <q-item-label><h1>{{ jdmEnvConfig.environment?.name }}</h1></q-item-label>
            <q-btn
              flat
              class="app-action-btn bg-negative"
              :title="$t('flow.overview.detail.modal.deleteJdmEnvConfig')"
              icon="delete"
              :disable="isInvalid"
              data-cy="buttonDeleteJdmEnvConfigModal"
              @click.capture.stop='openDeleteJdmEnvModal(jdmEnvConfigIndex)'
            />
          </div>

          <q-select
            v-model="jdmId"
            dense outlined
            readonly
            class="q-my-md"
            :label="$t('flow.overview.detail.modal.jdmSelectLabel')"
          />

          <q-select
            v-model="jdmEnvConfig.environment"
            map-options
            option-label="name"
            dense outlined
            use-input
            hide-selected
            fill-input
            :disable="isUpdatingJdmEnvironment"
            :options="environmentOptions"
            :label="$t('flow.overview.detail.modal.chooseEnvironment')"
            input-debounce="0"
            class="app-select-environment-input q-my-md"
            @filter="filterFn"
            aria-required="true"
            lazy-rules="ondemand"
          />

          <json-editor
            v-model="localEnvironmentConfigs[jdmEnvConfigIndex]"
            max-height="55vh"
            wrapped
            @is-valid-json="checkValidJson"
          />

          <q-separator v-if="jdmEnvConfigIndex + 1 < jdmEnvConfigs.length" horizontal />
        </q-item-section>

      </q-card-section>
    </template>

    <template #actions>
      <div class="block">
        <q-tooltip anchor="top middle" self="bottom middle" class="app-tooltip-mobile" v-if='maxAvailableEnvReached'>{{ $t('flow.overview.detail.modal.jdmEnvLimitReached') }}</q-tooltip>
          <q-btn dense unelevated round
                 :disable="maxAvailableEnvReached"
                 class="app-action-btn q-ml-lg"
                 data-cy="buttonAddJdmEnvConfigModal"
                 @click.capture.stop="addJdmEnvConfig"
          >
            {{ $t('flow.overview.detail.modal.addJdmEnvConfig') }} <q-icon name="add" class="q-ml-xs" />
          </q-btn>
      </div>

      <hr>

      <q-btn
        v-close-popup
        unelevated
        :label="$t('general.cancel')"
      />

      <!-- TODO: Outsource tooltip error msg to errorBanner -->
      <div>
        <q-tooltip anchor="top middle" self="bottom middle" class="app-tooltip-mobile sq-tooltip-negative" v-if='envAssignedMultipleTimes.length > 0'>
          {{ $t('flow.overview.detail.modal.envAssignedMultipleTimesError') }}
          <br>
          <ul>
            <li v-for="env in envAssignedMultipleTimes">{{ env }}</li>
          </ul>
        </q-tooltip>
        <q-btn
          flat
          :label="$t('general.save')"
          color="primary"
          :disable="isInvalid"
          data-cy="buttonSave"
          @click="validate()"
        />
      </div>
    </template>
  </sq-dialog>
</template>

<style scoped lang="scss">
  .context-container {
    max-height: 80%;
  }
  .sq-jdm-env-config-container {
    //margin-bottom: 1rem;
  }
</style>
