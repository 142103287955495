import script from "./CronjobModal.vue?vue&type=script&setup=true&lang=js"
export * from "./CronjobModal.vue?vue&type=script&setup=true&lang=js"

import "./CronjobModal.vue?vue&type=style&index=0&id=bd073d4e&lang=scss"

const __exports__ = script;

export default __exports__
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import QForm from 'quasar/src/components/form/QForm.js';
import QSelect from 'quasar/src/components/select/QSelect.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QToggle from 'quasar/src/components/toggle/QToggle.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QField from 'quasar/src/components/field/QField.js';
import qInstall from "../../../../node_modules/@quasar/app/lib/webpack/runtime.auto-import.js";
qInstall(script, 'components', {QDialog,QForm,QSelect,QItem,QItemSection,QInput,QToggle,QBtn,QField});
