<script>
import { defineComponent, ref } from 'vue'
import JsonEditor from 'components/JsonEditor.vue'
import LoadingIndicator from 'components/LoadingIndicator'
import { useQuasar } from 'quasar'

import SqDialog from 'components/Common/SqDialog.vue'
import {debounce, isEmpty} from "lodash";
import {flowService} from "src/services";
import {notifyError, notifySuccess} from "src/utils/notify";

export default defineComponent({
  name: 'JdmDispatchConditionModal',

  components: {
    SqDialog,
    LoadingIndicator,
    JsonEditor
  },
  emits: ['updated'],

  props: {
    jobDispatcherMapping: {
      type: Object,
      required: true
    }
  },

  setup (props) {
    const $q = useQuasar();

    const isUpdatingDispatchCondition = ref(false)
    const jdmId = ref(props.jobDispatcherMapping.id)

    function updateDispatchCondition() {
      isUpdatingDispatchCondition.value = true
    }

    return {
    qInstance: $q,
    updateDispatchCondition,
    isUpdatingDispatchCondition,
    jdmId
    }
  },
  data () {
    return {
      isLoading: false,
      localDispatchCondition: this.originalDispatchCondition,
      validJson: null,
      dispatchCondition: "",
      dispatchConditionKey: 0,
    }
  },

  computed: {
    isInvalid() {
      return (this.isLoading || this.isUpdatingDispatchCondition);
    }
  },

  methods: {
    checkValidJson(valid) {
      this.validJson = valid;
    },

    filterFn (val, update) {
      update(() => {
        this.environmentFilter = val;
      })
    },

    async update() {
      this.isLoading = true;
      try {

        try {
          this.isLoading = true

          const postData = {
            configuration: this.hasUiSchema ? this.moduleConfig : JSON.parse(this.moduleConfig),
            dispatchCondition: JSON.parse(this.dispatchCondition),
            override: JSON.parse(this.overrideConfig)
          }

          await flowService.patchJobDispatcherMapping(this.$props.jobDispatcherMapping.id, postData)

          notifySuccess(this.$t('jsonToForm.jdmScreen.notification.jdmSaved'))
          this.dispatchConditionKey += 1;
          this.$emit('updated', postData)
        } catch (error) {
          console.error('Something went wrong ' . error)

          notifyError(this.$t('jsonToForm.jdmScreen.notification.jdmSaveFailed'))
        } finally {
          this.isLoading = false
        }



      } catch(e) {
        this.$store.dispatch('alert/error', e);
      }
    },

    cancel() {
      this.localDispatchCondition = this.originalDispatchCondition;
    },
    save() {
      this.originalDispatchCondition = this.localDispatchCondition;
      this.update();
    },

    handleValidJson: debounce(function(valid) {
      this.isJdmConfigValid = valid
    }, 100),
  },

  mounted() {
    const jdm = this.$props.jobDispatcherMapping
    this.moduleConfig = this.hasUiSchema ? isEmpty(jdm?.configuration) ? null : jdm.configuration : JSON.stringify(jdm.configuration, null, 2)
    this.dispatchCondition = JSON.stringify(jdm?.dispatchCondition, null, 2)
    this.overrideConfig = JSON.stringify(jdm?.override, null, 2)
  }
})
</script>

<template>
  <sq-dialog
    type="edit"
    size="md"
    :loading="isLoading || isUpdatingDispatchCondition"
    @save="updateDispatchCondition"
    @hide="cancel()"
  >
    <template #title>
      {{ $t('flow.overview.detail.modal.editDispatchCondition') }}
    </template>

    <template #content>
    <q-card-section class="context-content">

      <json-editor
        v-model="dispatchCondition"
        :key="'dispatchCondition' + dispatchConditionKey"
        :disabled="isLoading"
        max-height="none"
        min-height="calc(100vh - 330px)"
        :use-schema-validator="false"
        @is-valid-json="handleValidJson"
      />

    </q-card-section>
    </template>

    <template #actions>
      <q-btn
        v-close-popup
        flat
        :label="$t('general.cancel')"
        class="text-bold"
      />

      <q-btn
        flat
        :label="$t('general.save')"
        color="white"
        class="bg-primary text-bold"
        :disable="isInvalid"
        data-cy="buttonSave"
        @click="save()"
      />
    </template>
  </sq-dialog>
</template>

<style scoped lang="scss">
  .context-container {
    max-height: 80%;
  }
</style>
