// Layouts
import MainLayout from "layouts/MainLayout";
// Pages
import Browser from "pages/Browser/Browser";
import Cronjobs from "pages/Cronjobs/Cronjobs";
import Dashboard from "pages/Dashboard/Dashboard";
import Entity from "pages/Entity/Entity";
import Error from "pages/Error";
import Execution from "pages/FlowExecution/Overview/FlowExecutionOverview";
import ExecutionDetail from 'pages/FlowExecution/Detail/FlowExecutionDetail';
import FlowDetail from 'pages/Flow/Detail/Detail';
import Environments from 'pages/Environments/EnvironmentOverview';
import Login from 'pages/Auth/Login.vue';
import Logs from "pages/Logs/Logs";
import Overview from "pages/Flow/Overview/Overview";
import PasswordRecoverRequest from 'pages/Auth/PasswordRecoverRequest';
import PasswordReset from "pages/Auth/PasswordReset";
import Preferences from "pages/Preferences/Preferences";
import UserManagement from "pages/UserManagement/UserManagement";
import ValidationInfoIndex from 'pages/ValidationInfo/ValidationInfoIndex'
import ValidationStats from 'pages/ValidationInfo/ValidationStats'
import FailedMessages from 'pages/FailedMessages/FailedMessages'
import ObjectBuilderIndex from 'pages/JsonToForm/ObjectBuilderIndex'
import JdmConfigBuilder from 'pages/Flow/JdmConfigBuilder.vue'
import FormPreview from 'pages/JsonToForm/FormBuilder/FormPreview.vue'
import FormBuilderForm from 'pages/JsonToForm/FormBuilder/FormBuilderForm.vue'
import ConfigSnippetIndex from 'pages/ConfigSnippets/ConfigSnippetIndex.vue'
import CacheUsageOverview from "pages/CacheUsage/Overview/CacheUsageOverview";
import CacheUsageDetails from "pages/CacheUsage/Details/CacheUsageDetails";

const routes = [
  {
    path: '/',
    component: MainLayout,
    children: [{ path: '', component: Login }],
    meta: {
      title: 'Home'
    }
  },
  {
    path: '/login',
    component: MainLayout,
    children: [
      {
        path: '',
        component: Login,
        name: 'Login'
      }
    ]
  },
  {
    path: '/dashboard',
    component: MainLayout,
    children: [{ path: '', component: Dashboard }],
    meta: {
      title: 'Dashboard'
    }
  },
  {
    path: '/passwordrecover',
    component: MainLayout,
    children: [{ path: '', component: PasswordRecoverRequest}]
  },
  {
    path: '/passwordreset',
    component: () => import('layouts/MainLayout.vue'),
    children: [{ path: '', component: () => import('pages/Auth/PasswordReset') }]
  },
  {
    path: '/passwordreset/:userId/:hash',
    component: MainLayout,
    children: [{ path: '', component: PasswordReset }]
  },
  {
    path: '/flow',
    component: MainLayout,
    children: [
      {
        path: '',
        component: Overview
      },
      {
        path: '/flow/:id/jdm/:jdmId',
        name: 'JdmConfigBuilder',
        component: JdmConfigBuilder,
        props: true,
        meta: {
          title: 'Flows Details'
        }
      },
    ],
    meta: {
      title: 'Flows'
    }
  },
  {
    path: '/flow/:id',
    component: MainLayout,
    children: [{ path: '', component: FlowDetail }],
    meta: {
      title: 'Flows Details'
    }
  },
  {
    path: '/flow/:id/:tabid',
    component: MainLayout,
    children: [{ path: '', component: FlowDetail }],
    meta: {
      title: 'Flows Details'
    }
  },
  {
    path: '/flowexecutions/',
    component: MainLayout,
    children: [{ path: '', component: Execution }],
    meta: {
      title: 'Flow Executions'
    }
  },
  {
    path: '/flowexecutions/:flowid',
    component: MainLayout,
    children: [{ path: '', component: Execution }],
    meta: {
      title: 'Flow Execution'
    }
  },
  {
    path: '/flowexecutions/detail/:flowid',
    component: MainLayout,
    children: [{ path: '', component: ExecutionDetail }],
    meta: {
      title: 'Flow Execution Details'
    }
  },
  {
    path: '/environments/',
    component: MainLayout,
    children: [{ path: '', component: Environments }],
    meta: {
      title: 'Environments'
    }
  },
  {
    path: '/logs/',
    component: MainLayout,
    children: [{ path: '', component: Logs }],
    meta: {
      title: 'Logs'
    }
  },
  {
    path: '/logs/:page',
    component: MainLayout,
    children: [{ path: '', component: Logs }],
    meta: {
      title: 'Logs'
    }
  },
  {
    path: '/documents/',
    component: MainLayout,
    children: [{ path: '', component: Entity }],
    meta: {
      title: 'Documents'
    }
  },
  {
    path: '/documents/:entitylist',
    component: MainLayout,
    children: [{ path: '', component: Entity }]
  },
  {
    path: '/user/',
    component: MainLayout,
    children: [{ path: '', component: UserManagement }],
    meta: {
      title: 'Users'
    }
  },
  {
    path: '/user/:userid',
    component: MainLayout,
    children: [{ path: '', component: UserManagement }],
    meta: {
      title: 'User Details'
    }
  },
  {
    path: '/cronjobs/',
    component: MainLayout,
    children: [{ path: '', component: Cronjobs }],
    meta: {
      title: 'Cronjobs'
    }
  },
  {
    path: '/cronjobs/:cronid',
    component: MainLayout,
    children: [{ path: '', component: Cronjobs }],
    meta: {
      title: 'Cronjob Details'
    }
  },
  {
    path: '/browser/',
    component: MainLayout,
    children: [
      { path: '', component: Browser }
    ],
    meta: {
      title: 'File Browser'
    }
  },
  {
    path: '/browser/:path',
    component: MainLayout,
    children: [
      { path: '', component: Browser },
      { path: '/browser/:path/:catchAll(.*)*', component: Browser }
    ],
    meta: {
      title: 'File Browser'
    }
  },
  {
    path: '/preferences/',
    component: MainLayout,
    children: [{ path: '', component: Preferences }],
    meta: {
      title: 'Preferences'
    }
  },
  {
    path: '/validation',
    name: 'ValidationInfo',
    component: MainLayout,
    children: [
      {
        path: 'infos',
        component: ValidationInfoIndex,
        name: 'ValidationInfoIndex',
        meta: {
          title: 'Validations'
        }
      },
      {
        path: 'stats',
        component: ValidationStats,
        name: 'ValidationStats',
        meta: {
          title: 'Validation Stats'
        }
      }
    ]
  },
  {
    path: '/form-builder',
    component: MainLayout,
    children: [
      {
        path: '',
        name: 'FormBuilder',
        component: ObjectBuilderIndex
      },
      {
        path: 'create',
        name: 'CreateForm',
        component: FormBuilderForm,
        props: true
      },
      {
        path: ':id/edit',
        name: 'UpdateForm',
        component: FormBuilderForm,
        props: true
      },
      {
        path: ':id/preview',
        name: 'FormPreview',
        component: FormPreview,
        props: true
      }
    ],
    meta: {
      title: 'Form Builder'
    },
  },
  {
    path: '/failed-messages/',
    component: MainLayout,
    children: [{ path: '', component: FailedMessages }],
    meta: {
      title: 'Failed Messages Test'
    }
  },
  {
    path: '/cache-usage',
    component: MainLayout,
    children: [
      { path: '', component: CacheUsageOverview },
      { path: 'detail/:flowExecutionId', component: CacheUsageDetails }
    ],
    meta: {
      title: 'Cache Usage'
    },
  },
  {
    path: '/config-snippets/',
    component: MainLayout,
    children: [
      {
        path: '',
        component: ConfigSnippetIndex,
        props: true
      }
    ],
    meta: {
      title: 'Config Snippets'
    }
  },
  // Always leave this as last one,
  {
    path: '/:catchAll(.*)*',
    name: "ERROR",
    component: Error,
    meta: {
      title: 'Not Found!'
    }
  }
]

export default routes
