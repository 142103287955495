import script from "./MainLayout.vue?vue&type=script&setup=true&lang=js"
export * from "./MainLayout.vue?vue&type=script&setup=true&lang=js"

import "./MainLayout.vue?vue&type=style&index=0&id=60624c49&lang=scss"

const __exports__ = script;

export default __exports__
import QLayout from 'quasar/src/components/layout/QLayout.js';
import QHeader from 'quasar/src/components/header/QHeader.js';
import QToolbar from 'quasar/src/components/toolbar/QToolbar.js';
import QDrawer from 'quasar/src/components/drawer/QDrawer.js';
import QPageContainer from 'quasar/src/components/page/QPageContainer.js';
import qInstall from "../../node_modules/@quasar/app/lib/webpack/runtime.auto-import.js";
qInstall(script, 'components', {QLayout,QHeader,QToolbar,QDrawer,QPageContainer});
