<script>
  import { defineComponent, ref } from 'vue'
  import JsonEditor from 'components/JsonEditor.vue'
  import LoadingIndicator from 'components/LoadingIndicator'
  import JobDispatcherMappingModal from '../JobDispatcherMappingModal/JobDispatcherMappingModal'
  import DeleteJobDispatcherMappingModal from '../JobDispatcherMappingModal/DeleteJobDispatcherMappingModal'
  import JdmEnvironmentModal from '../JobDispatcherMappingModal/JdmEnvironmentModal'
  import JdmDispatchConditionModal from '../JobDispatcherMappingModal/JdmDispatchConditionModal'
  import {flowService} from 'src/services'


  export default defineComponent({
    name: 'JdmItem',

    components: {
      LoadingIndicator,
      JsonEditor,
      JobDispatcherMappingModal,
      DeleteJobDispatcherMappingModal,
      JdmEnvironmentModal,
      JdmDispatchConditionModal
    },

    props: ['jdm', 'flow', 'environments'],

    emits: ['reload'],

    data() {
      return {
        showJdmEditModal: false,
        showJdmDeleteModal: false,
        triggerModal: '',
        isLoading: false,
        editJdmModal: ref(false),
        localJdmDispatchCondition: JSON.stringify(this.jdm.dispatchCondition, null, 2),
        localJdmModuleConfig: JSON.stringify(this.jdm.configuration, null, 2),
        showJdmEnvironmentModal: false,
        showJdmDispatchConditionModal: false
      }
    },

    methods: {
      handleJdmUpdated(data) {
        this.showJdmEditModal = false

        this.$emit('reload', data)
      },

      handleJdmEnvironmentUpdated(data) {
        this.showJdmEnvironmentModal = false;
      },

      handleDispatchConditionUpdated(data) {
        this.showJdmDispatchConditionModal = false
        this.handleJdmUpdated(data)
      },

      handleJdmDeleted() {
        this.isLoading = true;
        if (this.jdm.id) {
          this.$emit('reload', {deleted: true, jdmId: this.jdm.id})
        }
        this.isLoading = false;
      },

      async openJdmDeleteModal() {
        this.showJdmDeleteModal = true
      },

      async openJdmConfig() {
        // this.showJdmEditModal = true
        // return
        if (this.jdm.id) {
          this.$router.push({ name: 'JdmConfigBuilder', params: { id: this.$route.params.id, jdmId: this.jdm.id } })
        }
      },
      onStatusChange(value) {
        this.isLoading = true;
        flowService.toggleJobDispatcherMappingStatus(this.jdm.id, value)
        .then(e => {
          if (e.data.success) {
            if (e.data.message === 'jdmEnabled') this.$store.dispatch('alert/success',  'flow.overview.detail.jdmToggleEnabled');
            if (e.data.message === 'jdmDisabled') this.$store.dispatch('alert/success',  'flow.overview.detail.jdmToggleDisabled');
          }
        })
        .finally(() => this.isLoading = false)
        .catch(e => {
          if (value) this.$store.dispatch('alert/error',  'flow.overview.detail.jdmToggleEnabledFailure');
          if (!value) this.$store.dispatch('alert/error',  'flow.overview.detail.jdmToggleDisabledFailure');

          this.jdm.active = !this.jdm.active;
        });
      }


    },

    computed: {
      localDispatchConditionFormatted: {
        get: function() {
          return this.localJdmDispatchCondition;
        },
        set: function(val) {
          this.localJdmDispatchCondition = val;
        }
      },

      localModuleConfigFormatted: {
        get: function() {
          return this.localJdmModuleConfig;
        },
        set: function(val) {
            this.localJdmModuleConfig = val;
        }
      },

      activeToggleStatus() {
        return this.isLoading || this.flow.isRunning;
      },

      hasEnvironments() {
        return this.environments?.length > 0;
      }
    }
  })
</script>

<template>
<div class="q-mt-md relative-position">
  <loading-indicator v-if="isLoading" />

  <div
    class="app-jdm-item app-bg2 flex q-pa-sm"
    data-cy="buttonEditJdmModal"
    @click="openJdmConfig"
  >
    <div class="col-auto q-px-sm flex center text-h6">{{ jdm.id }}</div>
    <div class="col q-px-sm flex justify-between">
      <div class="q-mr-md flex column justify-center">
        <div class="app-text-weight-semibold app-text-break-all">{{ $props.jdm.label ?? $props.jdm.messageFqcn }}</div>
        <div v-if="$props.jdm.dispatchCondition.length !== 0">{{ $t('flow.overview.detail.modal.dispatchCondition') }}: {{ $props.jdm.dispatchCondition }}</div>
      </div>

      <div class="flex">
        <div v-if="hasEnvironments" class="flex items-center q-mr-md">
          <q-btn flat dense
                 class="app-action-btn"
                 :label="$t('flow.overview.detail.environmentBtn')"
                 @click.capture.stop="showJdmEnvironmentModal = true"
          />
          <jdm-environment-modal
            v-model="showJdmEnvironmentModal"
            :job-dispatcher-mapping="jdm"
            :environments="environments"
            @save="handleJdmEnvironmentUpdated"
          />
        </div>

        <div class="flex items-center">
          <q-btn flat dense
                 class="app-action-btn"
                 :label="$t('flow.overview.detail.dispatchConditionBtn')"
                 @click.capture.stop="showJdmDispatchConditionModal = true"
          />
          <jdm-dispatch-condition-modal
            v-model="showJdmDispatchConditionModal"
            :job-dispatcher-mapping="jdm"
            @updated="handleDispatchConditionUpdated"
          />
        </div>

      </div>

    </div>
    <div class="col-auto q-px-sm flex right">
      <q-toggle v-model="jdm.active" @update:model-value="onStatusChange" :disable="activeToggleStatus"/>
    </div>
    <job-dispatcher-mapping-modal
      v-model="showJdmEditModal"
      :job-dispatcher-mapping="jdm"
      :flow="flow"
      @updated="handleJdmUpdated"
    />
    <q-btn
      @click.capture.stop="openJdmDeleteModal"
      :disabled="isLoading"
      class="app-delete-btn app-delete-jdm self-start self-md-stretch q-pa-sm"
      icon-right="delete"
      flat dense unelevated
    />
    <delete-job-dispatcher-mapping-modal
      v-model="showJdmDeleteModal"
      :job-dispatcher-mapping="jdm"
      @delete="handleJdmDeleted"
    />
  </div>
</div>
</template>

<style lang="scss">
  .app-jdm-item {
    background: $background;
    transition: $transition;
    border-radius: $br;
    &:hover {
      background-color: mix($background, $primary, 80%);
      cursor: pointer;
      z-index: 1;
    }
  }
  body.body--dark {
    .app-jdm-item {
      background: $dark-page;
      &:hover {
        background-color: mix($dark-page, $primary, 80%);
      }
    }
  }
</style>
