<script>
import { defineComponent, ref } from 'vue'
import { environmentService } from 'src/services'
import { useStore } from 'vuex'

import SqDialog from 'components/Common/SqDialog.vue'

export default defineComponent({
  name: 'DeleteEnvironmentModal',

  components: {
    SqDialog
  },

  props: {
    environment: {
      type: Object
    }
  },

  emits: ['deleted'],

  setup (props, { emit }) {
    const $store = useStore();

    const isLoading = ref(false)

    function deleteEnvironment() {
      isLoading.value = true

      environmentService.deleteEnvironment(props.environment, (data) => {
        isLoading.value = false

        if (data?.data?.['@type'] === "hydra:Error") {
          $store.dispatch('alert/error', "environments.deleteEnvironmentFail", { root: true });
        } else {
          $store.dispatch('alert/success', "environments.deleteEnvironmentSuccess", {root: true});
        }

        emit('deleted', data)
      });
    }

    return {
      isLoading,
      deleteEnvironment
    }
  }
})
</script>

<template>
  <sq-dialog
    type="delete"
    :save-button-label="$t('general.delete')"
    :loading="isLoading"
    @save="deleteEnvironment"
  >
    <template #title>
      {{ $t('environments.deleteEnvironment') }}
    </template>

    <template #content>
      <div class="q-ml-sm q-my-md">
        {{ $t('environments.deleteEnvironmentConfirm') }}
        <b class="app-text-weight-semibold">#{{ environment.id }}: {{ environment.name }}</b>?
      </div>
    </template>
  </sq-dialog>
</template>
