<script setup>
  import { computed, ref, watch, onMounted, onBeforeUnmount } from 'vue'
  import { useRoute, useRouter } from 'vue-router'
  import { useStore } from 'vuex'
  import { useQuasar } from 'quasar'
  import { useI18n } from 'vue-i18n'
  import ErrorModal from 'components/ErrorModal.vue'
  import MainMenu from './menu/Menu.vue'
  import Toolbar from './menu/Toolbar.vue'
  import Alert from 'components/Alert.vue'


  const $q = useQuasar()
  const store = useStore()
  const route = useRoute()
  const router = useRouter()
  const { t } = useI18n({ useScope: 'global' })

  const menuDrawer = ref(false)
  const user = JSON.parse(localStorage.getItem('user'))
  const loggedInFlag = ref(!!user)
  const openDetails = ref(false)
  const localStorageValue = ref(localStorage.getItem('isInternalServerError'))

  // Computed
  const isLoggedIn = computed(() => {
    return store.state.authentication.user && !store.state.authentication.status.loggingIn && loggedInFlag.value
  });

  const alert = computed(() => store.state.alert)

  const build = computed(() => {
    try {
      return process.env.BUILD
    } catch (e) {
      return null
    }
  });

  // Methods
  function toggleMenuDrawer() {
    menuDrawer.value = !menuDrawer.value
  }

  function logout() {
    store.dispatch('authentication/logout').then(() => {
      router.push('/login')
    })
  }

  function footerOffset(offset) {
    return { minHeight: offset ? `calc(100vh - ${offset}px)` : `calc(100vh - 3rem)` }
  }

  function notifyRedirectUnauthorized() {
    const isUnauthorized = JSON.parse(window.localStorage.getItem('isUnauthorized'))
    const isLogin = route.name === 'Login'

    if (isUnauthorized && isLogin) {
      $q.notify({
        message: t('error.sessionExpired'),
        multiLine: true,
        color: 'negative'
      })
      window.localStorage.removeItem('isUnauthorized')
    }
  }

  onMounted(() => {
    notifyRedirectUnauthorized()
    if (localStorageValue.value) {
      openDetails.value = true
      store.dispatch('alert/setErrorData', localStorageValue.value)
    }
  });

  onBeforeUnmount(() => {
    window?.$eventBus?.off?.('ENTITY_CHANGE')
  });

  // Watchers
  watch(() => route.fullPath, () => {
    store.dispatch('alert/clear')
    const user = JSON.parse(localStorage.getItem('user'))
    loggedInFlag.value = !!user
  });
</script>

<template>
  <q-layout view="lHh Lpr lFf" :class="{ 'app-logged-in': isLoggedIn}">
    <q-header class="print-hide">
      <q-toolbar class="app-toolbar q-mx-auto">
        <toolbar class="q-mb-sm" @drawer-toggle="toggleMenuDrawer" :logged-in-flag="loggedInFlag" />
      </q-toolbar>
    </q-header>
    <q-drawer
      v-model="menuDrawer"
      show-if-above
      :width="245"
    >
      <!-- <menu> tag is browser native and thus reserved. That's why component has a prefix -->
      <main-menu :logged-in-flag="loggedInFlag" />
    </q-drawer>

    <q-page-container>
      <alert
          v-if="alert.message"
          icon="error"
          :message="alert.message"
          :class="`alert ${alert.type}`"
          :color="alert.color"
          :action="alert.action"
          :label="alert.label"
          :report="alert.report"
      />

      <error-modal v-model="openDetails" :report="localStorageValue" />

      <router-view :key="$route.path" :style-fn="footerOffset" />
      <div class="app-footer q-pt-sm q-pb-sm">
        <div class="flex justify-center self-end">
          {{ $t('footer.copyright', {year: new Date().getFullYear()}) }}
          <span v-if="build" class="app-build">&nbsp;&nbsp;
            {{ $t('footer.build', {build: build}) }}
          </span>
        </div>
        <div class="app-imprint flex justify-center">
          <a :href="$t('footer.imprintLink')" target="_blank">
            {{ $t('footer.imprint') }}
          </a>
        </div>
      </div>
    </q-page-container>
  </q-layout>
</template>

<style lang="scss">
  .app-build {
    color: $subtext;
  }
  .app-toolbar {
    background-color: $background;
  }
  body.body--dark {
    .app-toolbar {
      background-color: $dark-page;
    }
  }
</style>
