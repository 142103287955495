import jstEnUS from './jsonToForm/jtf-en-US'
import commonEnUS from './common/en-US'

export default {
  general: {
    appName: "synQup",
    cancel: "Cancel",
    close: "Close",
    create: "Create",
    edit: "Edit",
    delete: "Delete",
    save: "Save",
    apply: "Apply",
    applied: "Changes applied",
    confirm: "Confirm",
    clear: "Clear",
    add: "Add",
    yes: "Yes",
    no: "No",
    and: "and",
    or: "or",
    of: "of",
    search: "Search",
    loading: "Loading…",
    download: "Download",
    time: {
      momentAgo: "a moment ago",
      difference: "Time difference: {time}",
      timeAgo: "{timeData} ago",
      invalid: "Invalid date",
      singular: {
        secondsAgo: "{time} second ago",
        minutesAgo: "{time} minute ago",
        hoursAgo: "{time} hour ago",
        daysAgo: "{time} day ago",
        weeksAgo: "{time} week ago",
        monthsAgo: "{time} month ago",
        yearsAgo: "{time} year ago",
      },
      plural: {
        secondsAgo: "{time} seconds ago",
        minutesAgo: "{time} minutes ago",
        hoursAgo: "{time} hours ago",
        daysAgo: "{time} days ago",
        weeksAgo: "{time} weeks ago",
        monthsAgo: "{time} months ago",
        yearsAgo: "{time} years ago"
      },
      short: {
        "milliseconds": "ms",
        "seconds": "s",
        "minutes": "m",
        "hours": "h",
        "days": "d",
      },
      long: {
        "milliseconds": " msec",
        "seconds": " sec",
        "minutes": " min",
        "hours": " hr",
        "days": " day(s)",
      }
    },
    hotkeys: {
      save: "CTRL + s"
    },
    errorModal: {
      unknownErrorHeadline: "Unknown error"
    },
    logout: 'Logout',
    hidePassword: "Hide password",
    showPassword: "Show password",
    itemsPerPage: "Items per page",
    invalidJson: "Invalid JSON",
    invalidSchema: "Invalid schema. Please do not edit anything inside the \'_id'\ block",
    expandAll: "Expand all nodes",
    collapseAll: "Collapse all nodes",
    copyEditorContent: 'Copy Editor Content',
    clearEditor: 'Clear Editor',
    resetEditor: 'Reset Editor',
    noValidData: 'No valid data provided',
    prettify: 'Prettify code (Shortcut: CTRL + ALT + P within active editor)',
    readOnly: 'Editor content is read-only'
  },
  header: {
    preferences: "Preferences",
    logout: "Logout",
    menuToggle: "Toggle Menu"
  },
  footer: {
    copyright: "© {year} synQup",
    build: "Build: {build}",
    imprint: "Imprint",
    imprintLink: "https://www.synqup.com/en/imprint/"
  },
  menu: {
    selectLanguage: "Select Language",
    langs: {
      enUs: "English",
      deDe: "German"
    },
    routes: {
      login: "Login",
      dashboard: "Dashboard",
      flows: "Flows",
      flowExecutions: "Flow Executions",
      environments: "Environments",
      entities: "Entities",
      cronjobs: "Cronjobs",
      filebrowser: "File Browser",
      logs: "Logs",
      users: "Users",
      validationInfo: 'Validation Info',
      validationStats: 'Validation Stats',
      validation: 'Validation',
      cacheUsage: 'Cache Usage',
      failedMessages: 'Failed Messages',
      formBuilder: 'Form Builder',
      configSnippets: 'Config Snippets'
    },
    preferences: "Preferences",
    help: "Need help?",
    helpLink: "https://docs.synqup.com/index.html"
  },
  publicPages: {
    login: {
      headline: "Sign in to synQup",
      inputNameLabel: "E-Mail",
      inputPasswordLabel: "Password",
      rememberMe: "Remember me",
      passwordRecover: "Forgot password",
      submit: "Log In"
    },
    passwordRecover: {
      headline: "Forgot your password?",
      desc: "Request a new password by entering your mail address. A mail will be sent to you explaining all further steps.",
      inputLabel: "Mail",
      submit: "Request new password",
      checkMails: "Check your mails",
      submitSending: "Sending password request...",
      pwConfirmFail: "Password confirm does not match",
      pwTooShort: "Password must be 8 chars or longer",
      requestFail: "Failed to set new password. \nPlease contact the administrator if this problem persists.",
      requestSuccess: "Password reset succeeded."
    },
    passwordReset: {
      headline: "Reset password",
      desc: "Please choose a new password",
      user: "E-Mail address",
      userTitle: "Please re-enter your Mailadress for revalidation purposes",
      password: "New password",
      passwordTitle: "Please enter your new password",
      passwordConfirm: "Repeat new password",
      passwordConfirmTitle: "Please repeat the password you entered above",
      submit: "Reset password",
      requestSuccess: "Recovery request was sent. Please check your mails.",
      requestFail: "Failed sending password recovery mail. Mail correct?\nPlease contact the administrator if your mail is correct and this problem persists."
    }
  },
  dashboard: {
    headline: "Dashboard",
    desc: `Welcome to synQup`,
    card: {
      product: 'Products',
      category: 'Categories',
      customer: 'Customers',
      order: 'Orders'
    },
    messageQueue: {
      headline: "Message Queues",
      refresh: "Refresh",
      status: {
        positiveTooltip: "Done",
        negativeTooltip: "Failed",
        runningTooltip: "Running…",
      }
    },
    heartbeat: {
      headline: "System Health",
      delay: "Last heartbeat",
      delay2: "ago",
      noHeartbeat: "There has not been a heartbeat yet",
      heartbeat: "Heartbeat",
      timestamp: "Current time",
      gracePeriod: "Grace Period / Interval",
      graceExceededInfo: "Time since last heartbeat exceeds interval + grace period. This may mean that the heartbeat command has not been run yet or that no workers are running.",
      refresh: "Refresh",
      start: "Start",
      startSuccess: "Heartbeat successfully started. You can now refresh it.",
      fine: "Fine",
      warning: "Warning",
      error: "Error"
    },
    supervisor: {
      headline: "Supervisor",
      restartAll: "Restart All",
      restart: "Restart",
      stop: "Stop",
      refresh: "Refresh",
      restartConfirm: "Are you sure that you want to restart the Supervisor?",
      restartSuccess: "Supervisor successfully restarted",
      restartDenied: "Access Denied: You don't have the rights to perform the action",
      restartGroupSuccess: "Worker {identifier} successfully restarted",
      stopGroupSuccess: "Worker {identifier} successfully stopped",
      accessDenied: "Access Denied: You don't have the rights to perform the action",
      noData: "No data available",
      restartFailed: "Restarting Supervisor failed",
      table: {
        name: "Group",
        uptime: "Uptime",
        status: "Status",
        action: "Action"
      },
      state: {
        starting: "Starting",
        running: "Running",
        fatal: "Error",
        stopped: "Stopped",
      }
    },
    diskSpace: {
      headline: "Disk Space",
      freeSpace: "Free Space",
      usedSpace: "Used Space",
      refresh: "Refresh"
    },
    redisMemory: {
      headline: "Redis Memory Usage",
      refresh: "Refresh",
      loading: "Loading data…",
      noData: "No data"
    }
  },
  flow: {
    overview: {
      headline: "Flows",
      desc: `Here you can see the currently configured flows of your synQup instance.\n
        You can start executions of data flows, reconfigure the associated modules or create new data flows.`,
      showMore: "Show Flow Actions",
      start: "Start",
      end: "End",
      view: "View",
      addFlow: "Create",
      renameFlow: "Rename",
      deleteFlow: "Delete",
      addJdm: "Add Job Dispatcher Mapping",
      importFlow: "Import",
      exportFlow: "Export",
      noFlows: "There are no flows yet. Begin by adding one.",
      itemsPerPage: "Flows per page",
      modal: {
        namePlaceholder: "Name",
        nameRequired: "Flow name is required",
        jsonConfigHeadline: "JSON Config",
        flowsImportText: "Flow\'s Import Text",
        flowsExportText: "Flow\'s Export Text",
        endFlow: "End {flowName}?",
        endFlowConfirm: "This action may lead to unexpected results or broken states in connected systems. Are you sure you want to proceed?",
        endFlowSuccess: "Flow ended",
        addFlow: "Create new flow",
        importFlow: "Import",
        importButton: "Import",
        exportButton: "Download",
        importFile: "Import File",
        exportFlow: "Export Flows",
        exportFile: "Export File",
        editFlow: "Edit Flow",
        deleteFlow: "Delete Flow",
        deleteFlowConfirm: "Once you delete \'{flowName}\', all linked subitems and configurations of this flow will be lost.",
        deleteFlowConfirm2: "If you are absolutely sure, please confirm your choice by typing in the flow name below.",
        deleteFlowConfirmInputLabel: "Flow name",
        deleteFlowConfirmInputTitle: "Please type in the flow name: {flowName} (capitalization does not matter)",
        deleteFlowConfirmError: "Validation doesn\'t match",
        createFlow: "Create Flow",
        copySuccess: "Flow/s Export text copied!",
        copyFail: "There was an error on copying the Flow/s Export text!",
        exportSuccess: "Flow/s Export file has been downloaded!",
        exportFail: "There was an error downloading the Flow/s Export file!",
        flowCreationSuccess: "Flow created",
        flowCreationFailed: "Flow creation failed",
        flowDeletionSuccess: "Flow deleted",
        flowDeletionFailed: "Flow deletion failed",
        flowEditSuccess: "Flow edited",
        flowEditFailed: "Flow edit failed"
      },
      detail: {
        tabSelect: "Select tab",
        createJdmSelectTabError: "Select a Section (Input/Transformations/Output) to add a JDM",
        start: "Start",
        end: "End",
        renameFlow: "Rename",
        configuration: {
          title: "Flow Configuration",
          button: "Update",
          updateSuccess: "Flow-Configuration updated!",
          updateFail: "Flow Configuration update failed",
          updateFailJsonSyntax: "Error parsing JSON. Please check if your syntax is correct",
        },
        input: {
          title: "Input",
          empty: "There are no input Job Dispatcher Mappings yet"
        },
        transformation: {
          title: "Transformations",
          empty: "There are no transformation Job Dispatcher Mappings yet"
        },
        output: {
          title: "Output",
          empty: "There are no output Job Dispatcher Mappings yet"
        },
        addJdm: "Add Job Dispatcher Mapping",
        addJdmShort: "Add",
        addJdmDisabled: "Please select a tab in order to add a JDM to it",
        export: "Export",
        modal: {
          moduleConfig: "Module Config",
          dispatchCondition: "Dispatch Condition",
          addJdm: "Add Job Dispatcher Mapping",
          editJdm: "Edit Job Dispatcher Mapping",
          uniqueEnvWarning: "Environments must be unique! Please ensure that you are not assigning the same environment to multiple elements",
          createJdmEnvironment: "Create Job Dispatcher Mapping Environment",
          editJdmEnvironment: "Edit Job Dispatcher Mapping Environment",
          deleteJdm: "Delete Job Dispatcher Mapping",
          deleteJdmConfirm: "Are you sure that you want to delete JDM #{jdmId}: '{jdmName}'?",
          editItem: "Edit",
          deleteItem: "Delete",
          chooseProcessStep: "-- Choose process step --",
          chooseFQCN: "-- Choose FQCN --",
          noFQCN: "No results",
          invalidJson: "Error processing JSON. Please check your syntax.",
          jdmCreationSuccess: "Job Dispatcher Mapping created",
          jdmCreationFailed: "Failed creating Job Dispatcher Mapping",
          jdmEditSuccess: "Job Dispatcher Mapping edited",
          jdmEditFailed: "Failed editing Job Dispatcher Mapping",
          jdmDeletionSuccess: "Job Dispatcher Mapping deleted",
          jdmDeletionFailed: "Failed deleting Job Dispatcher Mapping",
          deleteJdmEnvironment: "Delete JDM Environment connection",
          deleteJdmEnvironmentConfirm: "Are you sure that you want to delete the connection between JDM {jdm} and {environment}?",
          jdmSelectLabel: "Job Dispatcher Mapping",
          chooseEnvironment: "-- Choose environment --",
          environmentMissing: "Please select an environment",
          editDispatchCondition: "Edit Dispatch Condition",
          dispatchConditionUpdated: "Dispatch Condition updated",
          jdmEnvConfigCreateSuccess: "JDM Environment Config created",
          jdmEnvConfigEditSuccess: "JDM Environment Config edited",
          jdmEnvConfigDeleteSuccess: "JDM Environment Config deleted",
          jdmEnvConfigCreateFail: "JDM Environment Config creation failed",
          jdmEnvConfigEditFail: "JDM Environment Config edit failed",
          jdmEnvConfigDeleteFail: "JDM Environment Config deletion failed",
          addJdmEnvConfig: "Add JDM Environment",
          jdmEnvLimitReached: "Maximum number of jdm environments reached. All environments are assigned.",
        envAssignedMultipleTimesError: "An environment was assigned multiple times. Please ensure that each environment is only assigned once.",
          deleteJdmEnvConfig: "Delete this JDM Environment Config",
        },
        jdmToggleEnabled: 'JDM Enabled',
        jdmToggleDisabled: 'JDM Disabled',
        jdmToggleEnabledFailure: 'JDM enabling failed',
        jdmToggleDisabledFailure: 'JDM disabling failed',
        environmentBtn: "Environments",
        dispatchConditionBtn: "Dispatch Condition",
        tab: {
          jdms: "Job Dispatcher Mappings",
          executions: "Executions"
        }
      }
    },
    executions: {
      headline: "Flow Executions",
      desc: "Overview of all your flow executions",
      noExecutions: "There are no executions yet. Create some by starting an input flow.",
      noExecutionsButton: "To flow overview",
      itemsPerPage: "Items per page",
      createdAt: "Created",
      updatedAt: "Updated",
      flow: "Flow",
      processStep: "Process Step",
      duration: "Duration",
      gotoDetail: "View detail page",
      gotoDetailNewTab: "View detail page in new tab",
      thLabel: {
        id: "ID",
        active: "Active",
        success: "Status",
        updatedAt: "Updated at",
        processStep: "Process Step",
        processStepShort: "Step",
        name: "Flow",
        duration: "Duration",
        createdAt: "Created at",
        time: "Updated",
        link: "Link"
      },
      filter: {
        headline: "Filter",
        stateFilter: "State filters",
        select: "Flow Selection",
        createdDateFrom: "Created after",
        createdDateTo: "Created before",
        statusActive: "Running",
        statusSuccess: "Successful",
        statusFail: "Failed",
        clearStatus: "Reset status filters",
        noItems: "No execution matches you filter settings",
        reset: "Reset all filters"
      },
      detail: {
        filter: "Search",
        reload: "Refresh",
        expandAll: "Expand all branches",
        collapseAll: "Collapse all branches",
        showPending: "Show pending",
        hidePending: "Hide pending",
        hideCompleted: "Hide completed",
        showCompleted: "Show completed",
        toggleNodeHeaderProgressDisplay: "Running parent progress",
        allFiltered: "All nodes match your filter",
        noResults: "No matching nodes found",
        workerNotStarted: "The flow was created, but no worker has started working on it yet. This can indicate a technical problem, as this flow was created more than 5 minutes ago.",
        workerNotStartedYet: "The flow was created, but no worker has started working on it yet. Either all workers are busy with other flows, or no workers are connected at all.",
        tabs: {
          list: "List",
          logs: "Logs"
        },
        autoRefresh: {
          disable: "No auto refresh",
          enable: "Automatically refresh every {seconds} seconds",
          autoRefreshActivatedInfo: "Auto refresh enabled. Refreshing every {seconds} seconds"
        },
        refresh: "Refresh",
        notFound: "Requested Flow Execution not found.",
        alreadyFinished: "Flow execution already finished",
        startNew: "Restart",
        modal: {
          restartFlowTitle: "Restart '{flowName}'?",
          restartFlowDesc: "Are you sure that you want to restart the flow? You will redirect to the flow execution page.",
          restartFlowSuccess: "Flow successfully restarted"
        }
      }
    },
    status: {
      running: "Running",
      runningTooltip: "The flow is currently progressing",
      positive: "Successful",
      positiveTooltip: "The flow has been successfully processed",
      negative: "Failed",
      negativeTooltip: "Flow processing failed",
      none: "None",
      noneTooltip: "Flow has never been run yet"
    },
    jumpToExecution: "Last execution",
    jumpToExecutionOverview: "All executions",
    openFlow: "Open",
    stopExecution: "End '{flowName}' Flow execution",
    flowStartedSuccess: "Flow successfully started",
    noActiveJdmError: "No active JDM",
    createdAt: "Created: {time}",
    updatedAt: "Updated: {time}",
    duration: "Duration: {time}",
    flowImportSuccess: "\'{flowName}\' successfully imported",
    flowImportFail: "Failure importing \'{flowName}\'"
  },
  environments: {
    headline: "Environments",
    addEnvironment: "Add",
    label: {
      id: "ID",
      name: "Name",
      description: "Description",
      action: ""
    },
    form: {
      name: "Name",
      description: "Description",
    },
    updateEnvironment: "Update",
    deleteEnvironment: "Delete",
    createEnvironmentSuccess: "Environment created",
    createEnvironmentFail: "Creation of the environment failed",
    updateEnvironmentSuccess: "Environment updated",
    updateEnvironmentFail: "Update of the environment failed",
    deleteEnvironmentConfirm: "Are you sure that you want to delete the following environment? - ",
    deleteEnvironmentSuccess: "Environment deleted",
    deleteEnvironmentFail: "Deletion of the environment failed",
    emptyList: "There are no environments yet"
  },
  cacheUsage: {
    noData: 'No Data Available',
    overview: {
      headline: 'Cache Usage',
      desc: 'Overview of the cache usage per flow execution',
      showKeys: 'Show Cache Keys',
      goToDetail: "View Detail Page",
      itemsPerPage: "Items Per Page",
      overallTotalSize: 'Total Size',
      tableColumns: {
        flowExecutionId: 'Flow Execution ID',
        flowName: 'Flow',
        totalSize: 'Total Size'
      }
    },
    detail: {
      headline: 'Cache Usage',
      overallTotalSize: 'Total Size',
      search: 'Search',
      refresh: 'Refresh',
      autoRefresh: {
        disable: "No auto refresh",
        enable: "Automatically refresh every {seconds} seconds",
        autoRefreshActivatedInfo: "Auto refresh enabled. Refreshing every {seconds} seconds"
      },
      tableColumns: {
        cacheKey: 'Key',
        cacheSize: 'Size'
      }
    }
  },
  entities: {
    title: "{entity}",
    titleEmpty: "Entities",
    selectFromList: "Please select an entity from the list",
    noEntities: "No entities available",
    desc: "You can filter entities by setting a filter query",
    entityListHeadline: "Your entities",
    maximize: "Open row in maximized editor",
    buttonExpand: "Open",
    buttonCollapse: "Close",
    clickToExpand: "Double click to expand",
    totalItems: "Total Entities: {totalItems}",
    refresh: "Refresh",
    delete: "Clear",
    refreshDescription: "Warning: unsaved changes will be lost!",
    refreshButton: "Refresh",
    filterActiveNotice: "Filter active",
    id: "Identifier",
    mongoId: "Mongo ID",
    date: "Timestamp",
    modal: {
      deleteRow: "Delete row",
      deleteRowConfirm: "Are you sure that you want to delete {rowId}?",
      deleteRowSuccess: "Entity {rowId} deleted",
      deleteRowError: "Couldn't delete {rowId}",
      deleteEntity: "Clear",
      deleteEntityConfirm: "Are you sure that you want to delete all entities of {entityList}?",
      deleteEntitySuccess: "All entities from {entityList} deleted",
      deleteEntityError: "Couldn't delete entities from {entityList}",
      deleteEntityClearButton: "Clear",
      saveQueryTitle: "Save Query",
      saveQueryLabel: "Please enter a name for the query. The name will also serve as an identifier.",
      saveQueryOverwriteWarning: "A query with this name already exists. If you proceed, the existing one will be overwritten.",
      saveQuerySuccess: "Query saved",
      loadQuerySuccess: "Query loaded",
      deleteQuerySuccess: "Query deleted",
      name: "Name",
      confirmDeleteQueryTitle: "Delete Query",
      confirmDeleteQueryLabel: "Are you sure that you want to delete '{query}'?"
    },
    saveQuery: "Save Query",
    loadQuery: "Load Query",
    deleteQuery: "Delete Query",
    submitQuery: "Search",
    clearQuery: "Reset filter",
    presetFieldFilled: "The query field is already filled",
    filterNoEntityRows: "No entity rows found",
    menu: {
      noEntityLists: "There are no entity types yet",
      filterEntityList: "Search...",
      allEntityListsFiltered: "No entity matches your filter",
      reload: "Refresh Entities"
    },
    preselectFilter: {
      byId: "by ID",
      byIdentifier: "by Identifier",
      sinceDate: "since date",
      betweenDates: "between two dates"
    },
    editRowSuccess: "Row \'{rowid}\' has been updated!",
    editRowFail: "Row \'{rowid}\' could not get updated",
    rowDateCreatedHint: "Created",
    rowDateUpdatedHint: "Updated",
    rowDateDeletedHint: "Deleted",
  },
  logs: {
    headline: "Logs",
    desc: "{nLogs} Logs",
    toggle: {
      negative: 'Toggle all red level logs',
      warning: 'Toggle all yellow level logs',
      info: 'Toggle all blue level logs',
      invert: 'Invert select',
      disableAll: 'Disable all',
      enableAll: 'Enable all'
    },
    download: {
      downloadOverview: "Download...",
      downloadAll: "Download all",
      downloadFiltered: "Download since...",
      date: "Date",
      toggleDatePicker: "Choose range",
      datePicker: {
        days: "Sunday_Monday_Tuesday_Wednesday_Thursday_Friday_Saturday",
        daysShort: "Sun._Mon._Tue._Wed._Thu._Fri._Sat._Sun.",
        months: "January_February_March_April_May_June_July_August_September_October_November_December",
        monthsShort: "Jan._Feb._Mar._Apr._May_Jun._Jul._Aug._Sep._Oct._Nov._Dec.",
        pluralDay: "days"
      },
      downloadPage: "Download logs of current page"
    },
    timestamp: "Timestamp",
    level: "Level",
    message: "Message",
    action: "Action",
    copiedToClipboard: "Log Info copied to clipboard",
    type: {
      all: "All",
      emergency: "Emergency",
      alert: "Alert",
      critical: "Critical",
      error: "Error",
      warning: "Warning",
      notice: "Notice",
      info: "Info",
      debug: "Debug"
    },
    searchPlaceholder: "Search",
    emptyList: 'No logs available',
    sortedAsc: 'sorted chronologically ascending',
    sortAsc: 'Ascending',
    sortedDesc: 'sorted chronologically descending',
    sortDesc: 'Descending',
    messageFilter: 'Search for text in log message',
    activeLevels: 'Active Levels',
    refresh: "Refresh",
    autoRefresh: {
      disable: "No auto refresh",
      enable: "Automatically refresh every {seconds} seconds",
      autoRefreshActivatedInfo: "Auto refresh enabled. Refreshing every {seconds} seconds"
    },
    noLevelsDisabled: "All levels active",
    singleLevelDisabled: "1 level disabled",
    multiLevelDisabled: "{x} levels disabled",
    allLevelsDisabled: "All levels disabled",
    tableActions: {
      copyEditorContent: 'Copy Log Message',
      viewLogInfo: 'View Log-Info'
    },
    logContext: "Log Context"
  },
  user: {
    headline: "Users",
    addUser: "Add",
    createUser: "Create",
    createUserSuccess: "User created",
    createUserFail: "User creation failed. Maybe the user already exists. Check the inactive users list.",
    updateUser: "Update",
    updateUserSuccess: "User updated",
    updateUserFail: "User update failed",
    deleteUser: "Delete User",
    deleteUserConfirm: "Are you sure you want to delete",
    deleteUserSuccess: "User deleted",
    deleteUserFail: "User deletion failed",
    itemsPerPage: "Users per page",
    form: {
      id: "ID",
      name: "Name",
      mail: "Mail",
      action: "Action",
      role: "Administrator",
      sendEmailNotification: 'Send Email Notification',
      password: "Password",
      overwritePassword: "Overwrite password. Leave empty if current password shall remain.",
      passwordRepeat: "Repeat Password",
      overwritePasswordRepeat: "Repeat password overwrite",
      rules: {
        name: {
          empty: "Name required"
        },
        mail: {
          empty: "Mail required",
          wrongRegex: "Please enter a valid mail address"
        },
        password: {
          empty: "Password required",
          tooShort: "Password must be at least {minLength} chars long",
          repeatDoesNotMatch: "Repeated password does not match"
        },
        roleSelect: {
          empty: "You must assign a role"
        }
      }
    },
    alreadyExists: "This user already exists",
    activeUsers: "Active Users",
    inactiveUsers: "Inactive Users"
  },
  failedMessages: {
    headline: "Failed Messages",
    searchString: "Message / Flow Execution ID",
    itemsPerPage: "Items per page",
    label: {
      flowExecutionId: "Flow Execution ID",
      message: "Message",
      createdAt: "Created At",
      file: "File",
    },
    modal: {
      title: "Failed Message on",
      jumpToExecution: "Jump to Flow Execution"
    },
    emptyList: 'There are no Failed Messages'
  },
  cronjob: {
    headline: "Cronjobs",
    addCronjob: "Add",
    editCronjob: "Edit",
    createCronjob: "Create",
    createCronjobSuccess: "Cronjob created",
    createCronjobFail: "Cronjob creation failed",
    updateCronjob: "Update",
    updateCronjobSuccess: "Cronjob successfully edited",
    updateCronjobFail: "Cronjob edit failed",
    deleteCronjob: "Delete",
    deleteCronjobConfirm: "Are you sure you want to delete Cronjob with ID ",
    deleteCronjobSuccess: "Cronjob successfully deleted",
    deleteCronjobFail: "Cronjob deletion failed",
    emptyList: "There are no Cronjobs yet",
    noFlows: "No Flows available",
    itemsPerPage: "Cronjobs per page",
    invalidExpression: "Invalid input",
    label: {
      id: "ID",
      cronExpression: "Cron Expression",
      active: "Active",
      flowId: "Flow ID",
      flowName: "Flow name",
      action: ""
    },
    form: {
      flowId: "Flow ID",
      expression: "Expression",
      active: "Active",
      activeState: "Cron active",
      rules: {
        empty: "Required"
      }
    },
    cronjobAlreadyExists: "This cronjob already exists"
  },
  browser: {
    headline: "File Browser",
    emptyFolder: "Folder is empty",
    filterNoMatch: "No file or folder matches your filter",
    backToParentFolder: "To parent folder",
    nameInput: {
      newFolder: "New folder name...",
      changeName: "New file name...",
      moveFile: "New path..."
    },
    rename: "Rename",
    move: "Move",
    cut: "Cut",
    copy: "Copy",
    delete: "Delete",
    paste: "Paste",
    download: "Download",
    addFilesystem: "Add filesystem",
    addFilesystemDesc: "Virtual file systems can be local folders or remote services (like FTP or S3)",
    editFilesystem: "Edit filesystem",
    deleteFilesystem: "Delete filesystem",
    deleteFilesystemDesc: "Are you sure you want to delete filesystem '{filesystem}'?",
    loadFilesystemError: "Filesystem couldn't be loaded",
    editFilesDesc: "Multi-Selection is enabled and works by left-clicking multiple items",
    deleteFileDesc: "Are you sure that you want to delete '{file}'?",
    addFolder: "Add folder",
    addFile: "Add file",
    copyFile: "Copy file",
    copyInsideItselfError: "A folder cannot be copied inside itself! Please adjust your selection.",
    folderDownloadNotPossible: "Only files can be downloaded. Please adjust your selection.",
    downloadFile: "Download",
    downloadRequestBlockedByClient: "File download failed. Either the file is broken or your browser blocks the download. Please check your settings and try again.",
    filter: "Filter",
    deleteFile: "Delete file",
    newFile: {
      headline: "New file",
      nameLabel: "File name (including file extension)",
      namePlaceholder: "example.txt",
      nameRequired: "File name required",
      fileContentHeadline: "File Content",
      urlLabel: "URL",
      urlPlaceholder: "Example: https://www.synqup.io/wp-content/themes/synqup/img/logo/logo.svg",
      urlRequired: "URL required",
      tab: {
        create: "Create file",
        uploadFromUrl: "Upload file from URL"
      }
    },
    modal: {
      namePlaceholder: "Name",
      nameRequired: "Filesystem name is required",
      jsonConfigHeadline: "JSON Config",
      createFilesystem: "Create",
      editFilesystem: "Edit",
      deleteFilesystem: "Delete",
      createFilesystemSuccess: "Filesystem created",
      editFilesystemSuccess: "Filesystem updated",
      deleteFilesystemSuccess: "Filesystem deleted",
      editFile: "Edit",
      addFileSuccess: "File added",
      editFileSuccess: "File updated",
      addFolderSuccess: "Folder created",
      deleteFileSuccess: "File deleted",
      renameFileSuccess: "File renamed",
      moveFileSuccess: "File moved",
      copyFileSuccess: "File copied",
      addFileError: "File creation failed",
      addFolderError: "Folder creation failed",
      renameError: "Rename failed",
      copyError: "Copy failed",
      moveError: "Move failed",
      deleteError: "Delete failed",
      editError: "Edit failed",
      forbiddenChar: "Forbidden char",
      fileSystemCreationFailed: "Something went wrong while creating the filesystem.",
      invalidInfo: "Invalid info provided. Please check the form and try again.",
      invalidJson: "Invalid JSON provided. {error}.",
      invalidPath: "The path you provided is not allowed.\n Please use one of the following paths: {acceptedPaths}",
      acceptedPathsPrefix: "Available paths that can be used:",
      createSubpathHint: "You can also define subpaths from the base paths above. Example: {path}"
    },
    fileContent: {
      size: "Size",
      date: "Date",
      visibility: "Visibility",
      content: "File content"
    }
  },
  preferences: {
    headline: "Preferences",
    avatarSelect: "User image",
    avatarHint: "Allowed file formats: png, jp(e)g, webp",
    user: "User",
    userFetchFailed: "Failed to fetch user data. Please report this issue to the administrator.",
    password: "Password",
    languageSelect: "Language",
    locale: {
      "en-US": "English",
      "de-DE": "German"
    },
    themeSelect: "Theme",
    theme: {
      auto: "Automatic",
      light: "Light mode",
      dark: "Dark mode"
    },
    enableDevSettings: 'Enable developer settings',
    enableDevErrorDetails: {
      moreDetails: 'More details in general errors (alerts)',
    },
    enableEmailNotification: 'Enable E-Mail Notification',
    emailNotifications: {
      flowFailed: 'Flow failed',
      messageFailed: 'Message failed',
      diskFull: 'Disk full',
      heartbeatFailed: 'Heartbeat failed'
    },
    preferenceUpdateFailed: 'Failed to update preference. Please refresh the page and try again.',
    preferenceUpdateSuccess: 'Preference updated',
    itemsPerPage: 'Elements per page',
    itemsPerPagePlaceholder: 'Min: {min}, max: {max}',
    heartbeatUnavailableInSeconds: 'Receive mail after heartbeat has been unavailable for x seconds (minimum: {minimumInterval})',
    heartbeatMailInterval: 'Send further mails if heartbeat remains unavailable every x seconds (minimum: {minimumInterval})'
  },
  validation: {
    headline: "Validation Info List",
    loadingData: "Loading data...",
    noData: "No data",
    paginationRowsPerPage: "Elements per page",
    documentType: "Document Type",
    search: "Document ID",
    messageSearch: "Message",
    entityType: "Entity Type",
    severity: "Severity",
    severityLevels: "Severity Levels",
    validationStats: "Validation Stats",
    table: {
      index: "#",
      severityLevel: "Severity Level",
      document: "Document",
      message: "Message"
    },
    noDataOrAllFiltered: "No data available, or all filtered",
    severityLevelTypes: {
      info: 'Info',
      alert: 'Alert',
      warning: 'Warning',
      danger: 'Danger'
    },
    severityChartType: "Severity type",
    pieChart: "Pie chart",
    barChart: "Bar chart",
    flowSeverities: "Flow Severities Group",
    alertEntities: "Alert Entities",
    entitiesWithAlerts: 'Entities with Alert Validations',
    entitiesWithoutAlerts: 'Alert-free entities',
    commonMessages: "Most Common Validation Messages",
    mostSeenEntities: "Entities with most Validation Messages"
  },
  configSnippets: {
    title: "Config Snippets",
    newSnippet: "New",
    editSnippet: "Edit",
    viewSnippet: "View",
    deleteSnippet: "Delete",
    deleteSnippetConfirm: "Are you sure you want to remove this snippet? Make sure this is not used in any of the existing configurations or other existing snippets because it will cause serious issues.",
    processing: "Processing...",
    creationSuccess: "Config snippet created successfully.",
    creationFail: "Something went wrong while creating a new config snippets. Please refresh and try again.",
    updateSuccess: "Config snippet updated successfully.",
    updateFail: "Failed to update Config Snippet. Please refresh the page and try again.",
    notFound: "Config Snippet not found",
    required: "This field is required",
    maxLength: "Character length should not be greater than {size}",
    snippetKey: "Key",
    snippetType: "Type",
    description: "Description",
    previewTitle: "{type}Snippet Preview",
    type: {
      config: "Config",
      template: "Template"
    },
    actions: "Actions",
    loadingFail: "Something went wrong while loading. Please refresh and try again.",
    deleteSuccess: "Snippet deleted.",
    deleteFail: "Delete failed. Something went wrong. Please refresh and try again.",
    snippetNotFound: "Snippet not found",
    regexFailed: 'String must start and end with %%',
    dialog: {
      title: {
        create: "Create new snippet",
        update: "Update '{title}' snippet"
      }
    }
  },
  error: {
    404: {
      title: "404",
      desc: "This page is not available (anymore)...",
    },
    backToIndex: "Back to Dashboard",
    backToPrevious: "Back to previous page",
    sessionExpired: "Oops! Your session has expired. Please login."
  },
  ...jstEnUS,
  ...commonEnUS
}
