<script>
  import { defineComponent, ref, computed } from 'vue'
  import { useStore } from 'vuex'
  import { useI18n } from 'vue-i18n'

  import SqDialog from 'components/Common/SqDialog.vue'
  import {environmentService} from "src/services";

  export default defineComponent({
    name: 'EnvironmentModal',

    components: {
      SqDialog
    },

    emits: ['saved'],

    props: ["page", "itemsPerPage", "environment"],

    setup (props, { emit }) {
      const $store = useStore();
      const $i18n = useI18n()

      const isLoading = ref(false)
      const environmentName = ref('')
      const environmentDescription = ref('')

      const environmentModalLabel = computed(() => $i18n.t(`environments.${props.environment ? 'updateEnvironment' : 'addEnvironment'}`))

      function initialize() {
        if (props.environment) {
          environmentName.value = props.environment.name
          environmentDescription.value = props.environment?.description
        }
      }

      function resetState() {
        environmentName.value = ''
        environmentDescription.value = ''
      }

      function saveEnvironment() {
        const formData = {
          name: environmentName.value,
          description: environmentDescription.value
        }

        isLoading.value = true;

        if (props.environment) {
          environmentService.updateEnvironment(props.environment, formData, (data) => {
            handleResponse(data);
          })
        } else {
          environmentService.createEnvironment(formData, (data) => {
            handleResponse(data);
          })
        }
      }

      function handleResponse(data) {
        const type = props.environment ? 'update' : 'create'

        isLoading.value = false

        if (data?.data['@type'] === "hydra:Error") {
          $store.dispatch('alert/error', "environments." + type + "EnvironmentFail", { root: true });
        } else {
          $store.dispatch('alert/success', "environments." + type + "EnvironmentSuccess", {root: true});

          emit('saved', data)

          resetState()
        }
      }

      return {
        isLoading,
        environmentName,
        environmentDescription,
        environmentModalLabel,
        initialize,
        resetState,
        saveEnvironment
      }
    },

    methods: {
      async handleShow() {
        this.initialize();
      }
    }
  })
</script>

<template>
  <sq-dialog
    :type="environment ? 'update' : 'create'"
    :loading="isLoading"
    @save="saveEnvironment"
    @show="handleShow"
    @hide="resetState"
  >
    <template #title>
      {{ environmentModalLabel }}
    </template>

    <template #content>
      <q-form @submit="saveEnvironment">

        <q-input
          v-model:model-value="environmentName"
          required
          dense
          :label="$t('environments.form.name')"
          class="q-mt-sm"
          maxlength="80"
        />

        <q-input
          v-model:model-value="environmentDescription"
          required
          dense
          :label="$t('environments.form.description')"
          class="q-my-sm"
          maxlength="80"
        />
      </q-form>
    </template>

    <template #saveButton>
      <q-btn flat
        type="submit"
        color="primary"
        :disabled="!environmentName"
        :label="environmentModalLabel"
        :class="{ 'disabled': !environmentName }"
        @click="saveEnvironment"
      />
    </template>
  </sq-dialog>
</template>
