import { route } from 'quasar/wrappers'
import { createRouter, createMemoryHistory, createWebHistory, createWebHashHistory } from 'vue-router'
import routes from './routes'

/*
 * If not building with SSR mode, you can
 * directly export the Router instantiation;
 *
 * The function below can be async too; either use
 * async/await or return a Promise which resolves
 * with the Router instance.
 */

export default route(function ( { store, ssrContext } ) {
  const createHistory = process.env.SERVER
    ? createMemoryHistory
    : (process.env.VUE_ROUTER_MODE === 'history' ? createWebHistory : createWebHashHistory)

  const Router = createRouter({
    scrollBehavior: () => ({ left: 0, top: 0 }),
    routes,

    // Leave this as is and make changes in quasar.conf.js instead!
    // quasar.conf.js -> build -> vueRouterMode
    // quasar.conf.js -> build -> publicPath
    history: createHistory(process.env.MODE === 'ssr' ? void 0 : process.env.VUE_ROUTER_BASE)
  })

  Router.beforeEach((to, from, next) => {
    document.title = to.meta.title ? `synQup | ${to.meta.title}` : 'synQup'
    // redirect to login page if not logged in and trying to access a restricted page
    const publicPages = ['/login', '/passwordrecover', '/passwordreset']; // DON'T include root ('/') in this array - it's a special case
    //const loggedIn = localStorage.getItem('user')
    const loggedIn = (store.state.authentication.user !== null);
    /* Allow parameters */
    let publicIndex = publicPages.findIndex((page) => {
      if (to.path.startsWith(page)) return to.path.startsWith(page);
    }, to.path);

    let authRequired = to.path !== '/' ? publicIndex < 0 : !publicPages.includes(to.path);

    // Check if the user is still logged-in
    const user = JSON.parse(localStorage.getItem('user'))
    if ((authRequired && !loggedIn) || (authRequired && !user)) {
      return next('/login');
    }
    if (!authRequired && loggedIn && user) {
      return next('/dashboard');
    }

    next()
  })
  return Router
})
