<script>
  /**
   * Milliseconds from which the modal will not be able to close after opening it.
   * This is so when users accidentally or intentionally double-click the button or any element
   * that will open a modal it will not close immediately.
   */
  const MS_FROM_INITIAL_PERSISTENCE = 200;

  export default {
    name: 'SqDialog',

    props: {
      model: {
        type: Boolean,
        default: false
      },

      isDraggable: {
        type: Boolean,
        required: false,
        default: true
      },

      type: {
        type: String,
        required: false
      },

      customClass: {
        type: String,
        required: false
      },

      cancelButtonLabel: {
        type: String,
        required: false
      },

      saveButtonLabel: {
        type: String,
        required: false
      },

      loading: {
        type: Boolean,
        required: false,
        default: false
      },

      loaderText: {
        type: String,
        required: false
      },

      size: {
        type: String,
        required: false,
        default: 'sm',
        validator(value) {
          return ['sm', 'md', 'lg'].includes(value)
        }
      },

      fullHeight: {
        type: Boolean,
        required: false,
        default: false
      },

      persist: {
        type: Boolean,
        required: false,
        default: false
      },

      disabled: {
        type: Boolean,
        required: false,
        default: false
      }
    },

    emits: ['save', 'show', 'hide'],

    data() {
      return {
        // initial seconds from which the modal will be persistent. Which means it will not close if escaped or the backdrop is clicked.
        initialPersistence: MS_FROM_INITIAL_PERSISTENCE,
        timeoutRunner: null,
        dragOffsetX: window.innerWidth / 2, // Start centered horizontally
        dragOffsetY: window.innerHeight / 2, // Start centered vertically
        startX: 0,
        startY: 0
      }
    },

    computed: {
      modalConfiguration() {
        const config = {
          toolbarIcon: 'add',
          color: 'primary'
        }

        if (this.type === 'update' || this.type === 'edit') {
          config.toolbarIcon = 'edit'
        }

        if (this.type === 'delete') {
          config.toolbarIcon = 'delete'
          config.color = 'negative'
        }

        if (this.type === 'cancel') {
          config.toolbarIcon = 'cancel'
          config.color = 'negative'
        }

        if (this.type === 'import') {
          config.toolbarIcon = 'arrow_downward'
        }

        if (this.type === 'export') {
          config.toolbarIcon = 'arrow_upward'
        }

        if (this.type === 'viewFile') {
          config.toolbarIcon = 'insert_drive_file'
        }

        if (this.type === 'error') {
          config.toolbarIcon = 'report'
          config.color = 'negative'
        }

        return config
      },

      shouldPersist() {
        if (this.$props.persist || this.initialPersistence > 0) return true;

        return this.loading
      },

      sizeClass() {
        const sizesAndClasses = {
          sm: 'size-small',
          md: 'size-medium',
          lg: 'size-large'
        }

        return sizesAndClasses[this.size]
      },

      undraggableArea() {
        return (event) => {
          const ignoredSelectors = [".q-field", ".app-editor", ".q-btn", ".pdf-vue3-main", "canvas", ".error-modal-trace-item", "pre", ".q-form", ".q-card__section"];
          return ignoredSelectors.some(selector => event.target.closest(selector));
        };
      }
    },

    methods: {
      handleSave() {
        this.$emit('save')
      },

      // do the countdown to lift initial persistence
      initialPersistenceCoolDown() {
        const LOOP_MS = 100;
        if (this.initialPersistence > 0) {
          this.initialPersistence -= LOOP_MS;
          if(this.initialPersistence < 0) this.initialPersistence = 0;
          this.timeoutRunner = setTimeout(() => {
            this.initialPersistenceCoolDown()
          }, LOOP_MS)
        }
      },

      handleShow() {
        this.initialPersistenceCoolDown()

        this.$emit('show')
      },

      handleHide() {
        clearTimeout(this.timeoutRunner)
        this.initialPersistence = MS_FROM_INITIAL_PERSISTENCE;
        this.dragOffsetX = window.innerWidth / 2;
        this.dragOffsetY = window.innerHeight / 2;
        setTimeout(function () { // Prevent visible position reset during fade out animation
          this.startX = 0;
          this.startY = 0;
        }, MS_FROM_INITIAL_PERSISTENCE);
        this.$emit('hide')
      },

      onDragStart(event) {
        if (this.undraggableArea(event)) {
          return; // Stop if mousedown is within an undraggable area
        }
        this.startX = event.clientX - this.dragOffsetX;
        this.startY = event.clientY - this.dragOffsetY;
        document.addEventListener("mousemove", this.onDragging);
        document.addEventListener("mouseup", this.onDragEnd);
      },
      onDragging(event) {
        this.dragOffsetX = event.clientX - this.startX;
        this.dragOffsetY = event.clientY - this.startY;
      },
      onDragEnd() {
        document.removeEventListener("mousemove", this.onDragging);
        document.removeEventListener("mouseup", this.onDragEnd);
      },
      handleResize() {
        // Simply recenter on Browser resize - any manual offset it reset when doing so.
        this.dragOffsetX = window.innerWidth / 2;
        this.dragOffsetY = window.innerHeight / 2;
      }
    },

    mounted() {
      // OPT: when the browser itself is resized, the modal needs to respond to this, otherwise it may drag out of the viewport
      window.addEventListener('resize', this.handleResize);
    },
  }
</script>

<template>
  <q-dialog
    class="sq-dialog"
    ref="sqDialogRef"
    :persistent="shouldPersist"
    @show="handleShow"
    @hide="handleHide"
  >
    <q-card
      class="inner-container draggable-dialog"
      :class="sizeClass, {'sq-dialog-full-height': $props.fullHeight, 'is--draggable': !$q.platform.is.mobile && $props.isDraggable}"
      :style="{ top: `${dragOffsetY}px`, left: `${dragOffsetX}px` }"
    >
      <div
        class="q-mb-sm"
         :class="{'is--draggable': !$q.platform.is.mobile && $props.isDraggable}"
         @mousedown.left="!$q.platform.is.mobile && $props.isDraggable ? onDragStart($event) : null"
      >
        <slot name="toolbar">
          <q-toolbar class="q-my-sm">
            <q-toolbar-title class="flex items-center">
              <q-avatar
                :icon="modalConfiguration.toolbarIcon"
                :color="modalConfiguration.color"
                class="q-mr-sm"
                text-color="white"
              />
              <slot name="title"></slot>
            </q-toolbar-title>

            <q-btn
              v-close-popup
              flat
              round
              dense
              icon="close"
            />
          </q-toolbar>
        </slot>

        <q-separator />

        <q-card-section class="q-mx-md">
          <slot name="content" />
        </q-card-section>

        <q-separator />

        <q-card-actions
          align="right"
          class="q-pt-md"
        >
          <slot name="actions">
            <slot name="cancelButton">
              <q-btn
                flat
                v-close-popup
                :label="cancelButtonLabel ||$t('general.cancel')"
                @click="handleHide"
              />
            </slot>

            <slot name="saveButton">
              <q-btn
                flat
                :color="modalConfiguration.color"
                :label="saveButtonLabel || $t('general.save')"
                :disabled="$props.disabled"
                @click="handleSave"
              />
            </slot>
          </slot>
        </q-card-actions>

        <q-inner-loading
          :showing="loading"
          color="primary"
        >
          <q-spinner
            color="primary"
            :thickness="3"
            size="38px"
          />

          <span v-if="loaderText" class="text-primary text-weight-medium">{{ loaderText }}</span>
        </q-inner-loading>
      </div>
    </q-card>
  </q-dialog>
</template>

<style lang="scss">
  .sq-dialog .inner-container {
    resize: both;
    width: auto;
    max-width: 95vw;
    min-height: 20vh;
    &.size-small {
      min-width: 40%;
    }
    &.size-medium {
      min-width: 60%;
    }
    &.size-large {
      min-width: 80%;
    }
  }

  .sq-dialog-full-height {
    min-height: 90vh;
  }

  .draggable-dialog {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 300px;
    .is--draggable {
      cursor: move;
      height: calc(100% - 1rem);
      .q-form, .q-card__section {
        cursor: auto;
        overflow: auto;
      }
    }
  }

  .pdf-vue3-main.pdf {
    cursor: initial;
    .pdf-vue3-backToTopBtn {
      cursor: pointer;
    }
  }
</style>
