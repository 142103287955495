<script>
import { defineComponent, ref } from 'vue'
import { useDialogPluginComponent } from 'quasar'

export default defineComponent({
  name: 'DeleteJobDispatcherMappingModal',
  emits: ["delete"],
  props: ["jobDispatcherMapping", "flow"],
  setup () {
    const { dialogRef, onDialogHide, onDialogOK, onDialogCancel } = useDialogPluginComponent();

    return {
      // This is REQUIRED;
      // Need to inject these (from useDialogPluginComponent() call)
      // into the vue scope for the vue html template
      dialogRef,
      onDialogHide: onDialogCancel,
      deleteJdm() {
        this.$emit('delete');
      },
      onCancelClick: onDialogCancel,
      onDelete() {
        onDialogOK()
      }
    }
  },
  data () {
    return {
      localJdm: {...this.$props.jobDispatcherMapping},
      deleteJdmModal: ref(false),
      isLoading: false
    }
  }
})
</script>

<template>
  <q-dialog ref="dialogRef" @hide="onDialogHide">
    <q-card class="q-pa-sm">

      <q-card-section class="row items-center q-pb-none">
        <div class="text-h6"><q-avatar icon="delete" color="negative" class="q-mr-sm" text-color="white" /> {{ $t('flow.overview.detail.modal.deleteJdm') }}</div>
        <q-space />
        <q-btn icon="close" flat round dense v-close-popup />
      </q-card-section>

      <q-card-section class="row items-center">
        <span class="q-ml-sm">{{ $t('flow.overview.detail.modal.deleteJdmConfirm', {jdmId: localJdm.id, jdmName: localJdm['label'] ?? localJdm['messageFqcn']}) }}</span>
      </q-card-section>

      <q-card-actions align="right">
        <q-btn flat :label="$t('general.cancel')" color="secondary" v-close-popup />
        <q-btn flat :label="$t('general.delete')" color="negative" @click="deleteJdm()" v-close-popup data-cy="deleteJdmConfirmButton" />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>
