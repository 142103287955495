import client from '../helpers/authenticated-axios'

export const versionService = {

  getCoreVersion(callback) {
    client().get('/core-version').then((response) => {
      callback(response.data);
    }).catch((error) => {
      callback(error.response);
    });
  },

  getFeatureFlags(callback) {
    client().get('/feature-flags').then((response) => {
      callback(response.data);
    }).catch((error) => {
      callback(error.response);
    });
  }

}
