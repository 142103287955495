<script>
  import { defineComponent } from 'vue'
  import { environmentService } from 'src/services/environmentService'
  import usePaginator from 'src/composables/usePaginator'
  import LoadingIndicator from 'components/LoadingIndicator.vue'
  import Paginator from 'components/Paginator.vue'
  import EnvironmentModal from "./components/EnvironmentModal.vue";
  import DeleteEnvironmentModal from "./components/DeleteEnvironmentModal.vue";

  export default defineComponent({
    name: 'EnvironmentOverview',

    components: {
      EnvironmentModal,
      DeleteEnvironmentModal,
      LoadingIndicator,
      Paginator
    },

    setup () {
      const { pagination, readFromQuery, updateRouteQuery, clearQuery, buildQueryData } = usePaginator()

      return {
        pagination,
        readFromQuery,
        updateRouteQuery,
        clearQuery,
        buildQueryData
      }
    },

    data () {
      return {
        selectedEnvironment: null,
        environmentList: [],
        showEnvironmentModal: false,
        showDeleteConfirmation: false,
        isLoading: true,
        columns: [
          {
            name: 'id',
            required: true,
            label: this.$t('environments.label.id'),
            align: 'left',
            field: row => row.id,
            format: val => `${val}`
          },
          {
            name: 'name',
            required: true,
            label: this.$t('environments.label.name'),
            align: 'left',
            field: row => row.name,
            format: val => `${val}`
          },
          {
            name: 'description',
            required: false,
            label: this.$t('environments.label.description'),
            align: 'left',
            field: row => row.description,
            format: val => `${val}`
          },
          {
            name: 'action',
            required: true,
            label: this.$t('environments.label.action'),
            align: 'left',
            field: 'action'
          }
        ]
      }
    },

    methods: {
      pageLoad() {
        this.isLoading = true

        this.updateRouteQuery(this.pagination)

        environmentService.getEnvironments(this.pagination.page, this.pagination.itemsPerPage, (data) => {
          this.pagination.totalItems = data['hydra:totalItems'] ?? 0
          this.pagination.pageCount = Math.ceil(this.pagination.totalItems / this.pagination.itemsPerPage)
          // Update page
          if (data['hydra:member']?.length > 0 ) {
            this.environmentList = data['hydra:member'];
            this.isLoading = false;
          } else {
            // There are no data available on current page anymore. Reload, jump to last one with data.
            if(data['hydra:totalItems'] > 0 && this.pagination.page !== 1) {
              this.pagination.page = this.pagination.pageCount;
              this.pageLoad();
            } else {
              // There are no items at all. Nothing to do here.
              // Need to empty the list Array when deleting the last available item though
              this.environmentList = [];
              this.isLoading = false;
            }
          }
        })
      },

      updateEnvironment(evt, environment) {
        this.showEnvironmentModal = true;
        this.selectedEnvironment = environment;
      },

      deleteEnvironment(environment) {
        this.showDeleteConfirmation = true;
        this.selectedEnvironment = environment;
      },

      handleSaved() {
        this.showEnvironmentModal = false
        const totalItems = this.pagination.totalItems

        if (!this.selectedEnvironment) {
          if (totalItems > 0 && (totalItems % this.pagination.itemsPerPage === 0)) {
            //  Creating first item or new page
            this.pagination.page = this.pagination.pageCount + 1
          }
          else {
            if (!(totalItems === 0 || (this.pagination.page === this.pagination.pageCount))) {
              // Currently on any previous page, jump to last page, since new created item is there
              this.pagination.page = this.pagination.pageCount
            }
          }
        }

        this.pageLoad();
        this.selectedEnvironment = null
      },

      handleDeleted(data) {
        this.showDeleteConfirmation = false
        this.selectedEnvironment = null

        if (data?.deleted === true && this.environmentList.length === 1) {
          this.pagination.page = this.pagination.page > 1 ? this.pagination.page - 1 : 1
        }

        this.pageLoad();
      },

      resetPageAndReload() {
        this.pagination.page = 1
        this.pageLoad()
      },
    },

    mounted() {
      this.readFromQuery(this.pagination)
      this.pageLoad();
    },

    unmounted() {
      this.clearQuery();
    }
  })
</script>

<template>
  <q-page class="block">
    <div class="q-pa-md">
      <div class="flex">
        <h1>{{ $t('environments.headline') }}</h1>

        <div class="q-ml-auto q-mb-lg">
          <q-btn
            v-if="!isLoading"
            flat
            dense
            icon-right="add"
            :label="$t('environments.addEnvironment')"
            :title="$t('environments.addEnvironment')"
            class="app-action-btn q-pa-sm"
            @click.capture.stop="showEnvironmentModal = true"
          />
        </div>
      </div>

      <loading-indicator v-if="isLoading" wrapper />

      <div v-if="!isLoading">
        <!-- :row-key="environmentsList.id" -->
        <div v-if="(environmentList && environmentList.length > 0)">
          <q-card flat>
            <q-card-section>
              <q-table
                :rows="environmentList"
                :row-key="environmentList.id"
                :columns="this.columns"
                color="primary"
                hide-bottom
                hide-pagination
                :loading="isLoading"
                :rows-per-page-options="[pagination.itemsPerPage]"
                flat
                class="sq-environments-table col-12 transparent q-pa-sm"
                @row-click="this.updateEnvironment"
              >
                <template v-slot:body-cell-action="props">
                  <q-td :props="props">
                    <div class="flex no-wrap">
                      <q-btn
                        icon-right="edit"
                        size="12px" flat dense
                        class="q-mr-sm q-mr-md-none"
                        :title="$t('environments.updateEnvironment')"
                        :disabled="isLoading"
                        @click.capture.stop="this.updateEnvironment(null, props.row)"
                      />
                      <q-btn
                        v-if="props.row"
                        icon-right="delete"
                        size="12px" no-caps flat dense
                        class="q-ml-sm negative-hover"
                        :title="$t('environments.deleteEnvironment')"
                        @click.capture.stop="this.deleteEnvironment(props.row)"
                      />
                    </div>
                  </q-td>
                </template>
              </q-table>
            </q-card-section>
          </q-card>

          <paginator
            v-model:page="pagination.page"
            v-model:itemsPerPage="pagination.itemsPerPage"
            :total-pages="pagination.pageCount"
            class="q-mt-lg"
            @update:page="pageLoad"
            @update:items-per-page="resetPageAndReload"
          />
        </div>

        <div v-if="!(environmentList && environmentList.length > 0)" class="app-empty-list flex justify-center items-center text-gray">{{ $t('environments.emptyList') }}</div>
      </div>
    </div>

    <environment-modal
        v-model="showEnvironmentModal"
        :environment="selectedEnvironment"
        :total-pages="pagination.pageCount"
        :items-per-page="pagination.itemsPerPage"
        @saved="handleSaved"
        @hide="selectedEnvironment = null"
    />

    <delete-environment-modal
        v-model="showDeleteConfirmation"
        :environment="selectedEnvironment"
        @deleted="handleDeleted"
    />

  </q-page>
</template>

<style lang="scss">
  .app-empty-list {
    min-height: 100px;
  }
</style>
