<script setup>
import { computed } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import { useQuasar } from 'quasar'
import MenuLink from './components/MenuLink.vue'
import EntitySelection from 'components/EntitySelection.vue'
import DropdownMenu from 'layouts/menu/components/DropdownMenu'

const props = defineProps({
  loggedInFlag: Boolean
});

const $q = useQuasar()
const store = useStore()
const route = useRoute()
const router = useRouter()
const { t } = useI18n()

const navListPublic = [
  {
    title: 'Login',
    link: '/login',
    icon: 'login'
  }
]
const navList = [
  {
    title: 'Dashboard',
    icon: 'img:assets/icons/menu/dashboard.svg',
    link: '/dashboard',
    name: 'dashboard'
  },
  {
    title: 'Flows',
    icon: 'img:assets/icons/menu/flows.svg',
    link: '/flow',
    name: 'flows'
  },
  {
    title: 'Flow Executions',
    icon: 'img:assets/icons/menu/flow-executions.svg',
    link: '/flowexecutions',
    name: 'flow-executions'
  },
  {
    title: 'Environments',
    icon: 'img:assets/icons/menu/environment.svg',
    link: '/environments',
    name: 'environments'
  },
  {
    title: 'Config Snippets',
    icon: 'data_object',
    link: '/config-snippets',
    name: 'config-snippets'
  },
  {
    title: 'Entities',
    icon: 'img:assets/icons/menu/entities.svg',
    name: 'entities',
    link: '/documents',
  },
  {
    title: 'Cronjobs',
    icon: 'img:assets/icons/menu/cronjobs.svg',
    link: '/cronjobs',
    name: 'cron-jobs'
  },
  {
    title: 'Filebrowser',
    icon: 'img:assets/icons/menu/filebrowser.svg',
    link: '/browser',
    name: 'file-browser'
  },
  {
    title: 'Logs',
    icon: 'img:assets/icons/menu/logs.svg',
    link: '/logs',
    name: 'logs'
  },
  {
    title: 'Users',
    icon: 'img:assets/icons/menu/users.svg',
    link: '/user',
    name: 'users'
  },
  /*{
    title: 'Validation',
    icon: 'rule',
    link: 'validation',
    name: 'validation-info',
    children: [
      {
        title: 'Validation Info',
        icon: 'grading',
        link: '/validation/infos',
        name: 'validation-info'
      },
      {
        title: 'Validation Stats',
        icon: 'addchart',
        link: '/validation/stats',
        name: 'validation-stats'
      },
    ]
  },
  {
    title: 'Failed Messages',
    icon: 'running_with_errors',
    link: '/failed-messages',
    name: 'failed-messages'
  },
  {
    title: 'Form Builder',
    icon: 'construction',
    link: '/form-builder',
    name: 'form-builder'
  },*/
  {
    title: 'Cache Usage',
    icon: 'img:assets/icons/menu/cache-usage.svg',
    link: '/cache-usage',
    name: 'cacheUsageOverview'
  },
];

// Computed properties
const isRefreshing = computed(() => store.state.preferences.isRefreshing)
const user = computed(() => store.state.authentication.user)
const status = computed(() => store.state.authentication.status)

const isLoggedIn = computed(() => {
  return user.value && !status.value?.loggingIn && props.loggedInFlag
})

const isMobile = computed(() => $q.screen.xs || $q.screen.sm)

// Methods
function checkIfToOpen(menuRoute) {
  return route.path.includes(`${menuRoute}/`)
}
</script>

<template>
  <div class="fit flex self-stretch">
    <q-list class="full-width">
      <q-item-label header class="flex text-weight-bold">
        <div class="app-logo-wrapper full-width">
          <q-img
              :src="!$q.dark.isActive ? 'assets/app-logo.svg' : 'assets/app-logo-light.svg'"
              class="app-logo cursor-pointer"
              position="0 50%"
              fit="contain"
              :alt="$t('general.appName')"
              @click.capture.stop="isLoggedIn ? router.replace('/dashboard') : router.replace('/login')"
          />
          <!--<span class="app-guest-menu-headline"><span class="app-name-accent1">syn</span><span class="app-name-accent2">Qup</span></span>-->
        </div>
      </q-item-label>

      <div class="app-menu" :class="{ 'disabled': isRefreshing }">
        <!-- Menu for guest -->
        <template v-if="!isLoggedIn" v-for="navItem in navListPublic" :key="navItem.title">
          <menu-link :title="navItem.title" :caption="navItem.caption" :icon="navItem.icon" :link="navItem.link" />
        </template>
        <!-- Menu for logged in user -->
        <template
            v-if="isLoggedIn"
            v-for="navItem in navList"
            :key="navItem.name"
        >
          <entity-selection
              class="q-mx-md"
              v-if="navItem.title === 'Entities' && isMobile"
              :icon="navItem.icon"
              data-cy="menuLinkEntities"
              is-menu
          />
          <dropdown-menu
              v-else-if="navItem.children"
              :menu="navItem"
              :is-opened="checkIfToOpen(navItem.link)"
          />
          <menu-link
              v-else
              v-bind:data-cy="'menuLink' + navItem.title"
              :title="navItem.title"
              :caption="navItem.caption"
              :icon="navItem.icon"
              :link="navItem.link"
          />
        </template>
      </div>
    </q-list>
    <div class="app-help-container q-ma-md flex self-end q-pb-md">
      <a class="app-help flex inline items-end" :href="$t('menu.helpLink')" target="_blank">
        <q-icon class="sq-help-icon q-mr-md" inline name="help_outline" size="xs" />
        <div class="sq-help-label items-end full-height">{{ $t('menu.help') }}</div>
      </a>
    </div>
  </div>
</template>

<style lang="scss">
.q-drawer {
  .app-logo-wrapper {
    .app-logo {
      height: 3rem;
    }
  }
  a.app-help {
    text-decoration: none !important; // Important required to force overwrite of typography styles
    &:hover .sq-help-label {
      text-decoration: underline !important;
    }
  }
  .app-menu h2 {
    font-size: 1rem;
  }
  .q-item .q-item__section--side > .q-icon {
    font-size: 1.25rem;
    top: -1px;
  }
}

body.body--dark {
  .q-drawer {
    background-color: $dark
  }
}
</style>
