<script setup>
import { ref, computed } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { useQuasar } from 'quasar'

// Emits
const emit = defineEmits(['drawer-toggle'])

// Props
const props = defineProps({
  loggedInFlag: Boolean
});

const $q = useQuasar()
const store = useStore()
const router = useRouter()

const tooltipMenuToggle = ref(false)
const closeMenuTimer = ref(null)
const userMenu = ref(null)

// Computed properties
const user = computed(() => store.state.authentication.user)
const status = computed(() => store.state.authentication.status)

const isLoggedIn = computed(() => {
  return user.value && !status.value?.loggingIn && props.loggedInFlag
})

const userDisplayName = computed(() => {
  return user.value?.display_name || ''
})

const userUsername = computed(() => {
  return user.value?.username || ''
})

const userInitials = computed(() => {
  const name = user.value?.display_name
  return name ? name.split(' ').map(i => i[0]).join('') : ''
})

// Methods
function logout() {
  store.dispatch('authentication/logout').then(() => {
    router.push('/login')
  })
}

function toggleDrawer() {
  tooltipMenuToggle.value = false
  emit('drawer-toggle')
}

function openUserMenu() {
  clearTimeout(closeMenuTimer.value)
  userMenu.value?.show()
}

function scheduleCloseUserMenu() {
  closeMenuTimer.value = setTimeout(() => {
    userMenu.value?.hide()
  }, 200)
}

function cancelCloseUserMenu() {
  clearTimeout(closeMenuTimer.value)
}
</script>

<template>
  <div class="flex full-width justify-between q-mt-sm">
    <div class="flex inline">
      <q-btn
        flat dense rounded
        class="app-topbar-btn lt-md"
        icon="img:assets/icons/drawer.svg"
        :title="$t('header.menuToggle')"
        aria-label="Menu"
        @click.capture.stop="toggleDrawer"
      >
        <!--
        <q-tooltip class="app-tooltip-mobile" v-if="$q.platform.is.mobile" v-model="tooltipMenuToggle">
          {{ $t('header.menuToggle') }}
        </q-tooltip>
        -->
      </q-btn>
    </div>

    <q-btn
      v-if="isLoggedIn"
      class="app-user-btn-header"
      color="primary"
      flat
      dense
      @mouseover="openUserMenu"
      @mouseleave="scheduleCloseUserMenu"
      :ripple="false"
    >
      <div class="flex q-mr-sm app-user-container" :class="{'app-user-viewport-xs': $q.screen.xs}">
        <div class="app-avatar self-center non-selectable line-height-1">
          {{ userInitials }}
        </div>
        <div class="app-user q-ml-md flex column items-start non-selectable">
          <span class="app-user-real-name">{{ userDisplayName }}</span>
          <span class="app-user-technical-name">{{ userUsername }}</span>
        </div>
      </div>

      <q-menu
        ref="userMenu"
        auto-close
        anchor="bottom middle"
        self="top middle"
        @mouseover="cancelCloseUserMenu"
        @mouseleave="scheduleCloseUserMenu"
        transition-show="jump-down"
        transition-hide="jump-up"
      >
        <q-list style="min-width: 200px">
          <q-item clickable to="/preferences">
            <q-item-section avatar><q-icon name="settings" size="xs" /></q-item-section>
            <q-item-section>{{ $t('header.preferences') }}</q-item-section>
          </q-item>
          <q-item clickable @click.capture.stop="logout">
            <q-item-section avatar><q-icon name="logout" size="xs" /></q-item-section>
            <q-item-section>{{ $t('header.logout') }}</q-item-section>
          </q-item>
        </q-list>
      </q-menu>
    </q-btn>
  </div>
</template>

<style lang="scss">
.q-header {
  background-color: $background;

  .app-toolbar {
    max-width: $page-max-width;
  }
}

.app-user-btn-header {
  padding: 0 2rem;
  margin-right: -1rem;
  transition: .25s;
  border-radius: $br-softer;

  .app-user-container:not(:hover) {
    color: $dark;
  }
}

body.body--dark {
  .q-header {
    background-color: $dark-page;
  }

  .app-user-btn-header {
    .app-user-container:not(:hover) {
      color: $light;
    }
  }
}

.app-user div {
  line-height: 1.25;
}

.app-name-accent1 {
  font-weight: bold;
  color: $dark;
}

.app-name-accent2 {
  font-weight: bold;
  color: $primary;
}

.app-avatar {
  background: $primary;
  color: $light;
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.app-user {
  .app-user-real-name {
    font-weight: bold;
  }

  .app-user-technical-name {
    font-weight: normal;
    font-size: .8rem;
  }
}

.app-topbar-btn {
  color: $dark;
}

.app-user-viewport-xs {
  .app-avatar {
    display: none;
  }

  .app-user {
    margin-left: unset;

    .app-user-real-name, .app-user-technical-name {
      margin-left: unset;
      max-width: 35vw;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

body.body--dark {
  .q-drawer {
    .app-name-accent1 {
      color: white;
    }
  }

  .app-avatar {
    background: $primary-dark-page;
  }

  .app-user-real-name, .app-topbar-btn {
    color: $light;
  }

  .app-user-technical-name {
    color: $grey;
  }
}
</style>
