import client from '../helpers/authenticated-axios'

export const environmentService = {

  // ================================== environment ==================================

  getEnvironments(page = null, itemsPerPage = null, callback) {
    const paginatedData = page && itemsPerPage ? '?page=' + page + '&itemsPerPage=' + itemsPerPage : '';
    client().get('/environments' + paginatedData).then((response) => {
      callback(response.data)
    }).catch((error) => {
      callback(error);
    });
  },
  createEnvironment(formData, callback) {
    client().post('/environments', {"name": formData.name, "description": formData.description}).then((response) => {
      callback(response.data);
    }).catch((error) => {
      callback(error.response);
    });
  },
  updateEnvironment(updatedEnvironment, formData, callback) {
    client().put('/environments' + '/' + updatedEnvironment.id, {"name": formData.name, "description": formData.description}).then((response) => {
      callback(response.data);
    }).catch((error) => {
      callback(error.response);
    });
  },
  deleteEnvironment(deletedEnvironment, callback) {
    client().delete('/environments' + '/' + deletedEnvironment.id).then((response) => {
      callback(response.data);
    }).catch((error) => {
      callback(error.response);
    });
  },

  // ================================== job_dispatcher_mapping_config ==================================

  getJdmEnvironments(jdmId = null, callback) { // jdmEnvConfigId is optional and acts as a filter
    const filter = jdmId ? 's/jdmc/' + jdmId : '_configs';
    client().get('/job_dispatcher_mapping' + filter).then((response) => {
      callback(response.data);
    }).catch((error) => {
      callback(error.response);
    });
  },

  replaceJdmEnvironment(jdmEnvConfigId, jdmId, envId, config, callback) {
    console.log("JDMENVCONFIGID", jdmEnvConfigId, "JDMID", jdmId, "ENVID", envId, "CONFIG", config);
    client().put('/job_dispatcher_mapping_configs' + '/' + jdmEnvConfigId, {
      jobDispatcherMapping: `/api/job_dispatcher_mappings/${String(jdmId)}`,
      environment: `/api/environments/${String(envId)}`,
      environmentId: envId,
      config
    }).then((response) => {
      callback(response.data);
    }).catch((error) => {
      callback(error.response);
    });
  },

  replaceMultipleJdmEnvironments(bulk, callback) {
    console.log("MULTI PUT", bulk)

    client().put('/job_dispatcher_mapping_configs/bulk_update', bulk).then((response) => {
      callback(response.data);
    }).catch((error) => {
      callback(error.response);
    });
  },

  // PATCH not working
  /*updateJdmEnvironment(jdmEnvConfigId, jdmId, envId, config, callback) {
    client().patch('/job_dispatcher_mapping_configs' + '/' + jdmEnvConfigId, {
      jobDispatcherMapping: `/api/job_dispatcher_mappings/${String(jdmId)}`,
      environment: `/api/environments/${String(envId)}`,
      config
    }, {headers: {'Content-Type': 'application/vnd.api+json'}}).then((response) => {
      callback(response.data);
    }).catch((error) => {
      callback(error.response);
    });
  },*/

  deleteJdmEnvironment(deletedJdmEnvironmentId, callback) {
    client().delete('/job_dispatcher_mapping_configs' + '/' + deletedJdmEnvironmentId).then((response) => {
      callback(response.data);
    }).catch((error) => {
      callback(error.response);
    });
  },

  createJdmEnvironment(jdmId, envId, config, callback) {
    client().post('/job_dispatcher_mapping_configs', {
      jobDispatcherMapping: `/api/job_dispatcher_mappings/${String(jdmId)}`, // IRI
      environment: `/api/environments/${String(envId)}`,
      config
    }).then((response) => {
      callback(response.data);
    }).catch((error) => {
      callback(error.response);
    });
  }
}
